import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd'; // Removed Draggable
// @mui
import { Paper, Stack, Button } from '@mui/material';
// redux
import { useDispatch } from '../../../redux/store';
// import { deleteColumn, updateColumn, addTask, deleteTask } from '../../../redux/slices/kanban';
// components
import Iconify from '../../../components/Iconify';
//
// import KanbanAddTask from './KanbanTaskAdd';
import KanbanTaskCard from './KanbanTaskCard';
import KanbanColumnToolBar from './KanbanColumnToolBar';

// ----------------------------------------------------------------------

KanbanColumn.propTypes = {
  column: PropTypes.object,
  index: PropTypes.number,
};

export default function KanbanColumn({ column, index }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { board } = useSelector((state) => state.kanban);
  const [open, setOpen] = useState(false);

  const { name, cardIds, id } = column;

  // console.log('column:', column);
  // console.log('cardIds:', cardIds);
  // console.log('board:', board);

  const handleOpenAddTask = () => {
    setOpen((prev) => !prev);
  };

  const handleCloseAddTask = () => {
    setOpen(false);
  };

  return (
    <Paper
      variant="outlined"
      sx={{ px: 2, bgcolor: 'grey.5008' }}
    >
      <Stack spacing={3}>
        <KanbanColumnToolBar columnName={name} />

        <Droppable droppableId={id} type="task">
          {(provided) => (
            <Stack 
              ref={provided.innerRef} 
              {...provided.droppableProps} 
              spacing={2} 
              width={280}
              sx={{ minHeight: '600vh' }}  // Set minimum height here
            >
              {cardIds.map((cardId, index) => (
                <KanbanTaskCard
                  key={cardId}
                  card={board?.cards[cardId]}
                  index={index}
                />
              ))}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </Stack>
    </Paper>
  );
}
