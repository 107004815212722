import React from "react";

import { Avatar, CardHeader } from "@mui/material";

import { i18n } from "../../translate/i18n";

const TicketInfo = ({ contact, ticket, onClick }) => {
	let displayName = contact.name;

	if (displayName.length > 12) {
		displayName = `${displayName.slice(0, 12)}...`;
	}

	return (
		<>
			<CardHeader
				style={{ padding: '10px', cursor: "pointer" }}
				onClick={onClick}
				titleTypographyProps={{ noWrap: true }}
				subheaderTypographyProps={{ noWrap: true }}
				avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
				title={displayName}
				subheader={
					ticket.user &&
					`${ticket.user.name} #${ticket.id}`
				}
			/>
		</>

	);
};

export default TicketInfo;

