import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { makeStyles } from 'tss-react/mui';
import { IconButton } from "@mui/material";
import { MoreVert, Replay, Close } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketContext } from '../TicketContext';

const useStyles = makeStyles()((theme) => {
  return {
    actionButtons: {
      marginTop: '-15px',
      marginRight: 6,
      flex: "none",
      alignSelf: "center",
      marginLeft: "auto",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    mobileReturn: {
      '@media (max-width: 1600px)': {
        display: 'none'
      },
    },
    mobileResolve: {
      '@media (max-width: 1000px)': {
        display: 'none'
      },
    },
  }
});

const TicketActionButtons = ({ ticket, fromEmbedded }) => {
  const { classes } = useStyles();
  const history = useHistory();
  // const { ticketId } = useParams();
  const { ticketId: ticketIdFromParams } = useParams();
  const ticketIdFromContext = useContext(TicketContext);
  const ticketId = ticketIdFromParams || ticketIdFromContext;
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const [ closeTicketModalOpen, setCloseTicketModalOpen ] = useState(false);
  const ticketOptionsMenuOpen = Boolean(anchorEl);
  const { user } = useContext(AuthContext);

  const handleOpenTicketOptionsMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseTicketOptionsMenu = e => {
    setAnchorEl(null);
  };

  const handleOpenFinishModal = () => {
    setCloseTicketModalOpen(true);
    //setSelectedQueue(null);
  };

  const handleCloseFinishModal = () => {
    setCloseTicketModalOpen(false);
    //setSelectedQueue(null);
  };

  const handleUpdateTicketStatus = async (e, status, userId) => {
    setLoading(true);
    try {
      let payload = {
        status: status,
        userId: userId || null
      };

      // conditionally add stage if status is 'closed'
      if (status === 'closed') {
        payload.stage = 'draft';
      }

      await api.put(`/chats/${ ticket.id }`, payload);
      setLoading(false);
      if (!fromEmbedded) {
        if (status === "open") {
          history.push(`/chats/${ticketId}`);
        } else {
          history.push("/chats");
        }
      }
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  return (
    <div className={ classes.actionButtons }>
      <div
        open={ closeTicketModalOpen }
        onClose={ handleCloseFinishModal }
      />
      { ticket.status === "closed" && (
        <>
        <ButtonWithSpinner
          loading={ loading }
          startIcon={ <Replay /> }
          size="small"
          onClick={ e => handleUpdateTicketStatus(e, "open", user?.id) }
        >
          { i18n.t("messagesList.header.buttons.reopen") }
        </ButtonWithSpinner>
        <IconButton style={ { margin: '0px 5px 0px 5px' } } onClick={ handleOpenTicketOptionsMenu }>
            <MenuIcon />
          </IconButton>
          <TicketOptionsMenu
            onReturn={ e => handleUpdateTicketStatus(e, "pending", null) }
            onResolve={ e => handleOpenFinishModal() }
            ticket={ ticket }
            anchorEl={ anchorEl }
            menuOpen={ ticketOptionsMenuOpen }
            handleClose={ handleCloseTicketOptionsMenu }
          />
        </>
      ) }
      { ticket.status === "open" && (
        <>
          <ButtonWithSpinner
            className={ classes.mobileResolve }
            loading={ loading }
            style={ { margin: '0px 10px' } }
            startIcon={ <Close /> }
            variant="outlined"
            size="small"
            onClick={ e => handleUpdateTicketStatus(e, "closed", user?.id) }
          >
            { i18n.t("messagesList.header.buttons.resolved") }
          </ButtonWithSpinner>
          <ButtonWithSpinner
            className={ classes.mobileReturn }
            loading={ loading }
            style={ { margin: '0px 10px' } }
            startIcon={ <Replay /> }
            variant="outlined"
            size="small"
            onClick={ e => handleUpdateTicketStatus(e, "pending", null) }
          >
            { i18n.t("messagesList.header.buttons.return") }
          </ButtonWithSpinner>
          <IconButton style={ { margin: '0px 5px 0px 5px' } } onClick={ handleOpenTicketOptionsMenu }>
            <MenuIcon />
          </IconButton>
          <TicketOptionsMenu
            onReturn={ e => handleUpdateTicketStatus(e, "pending", null) }
            onResolve={ e => handleUpdateTicketStatus(e, "closed", user?.id) }
            ticket={ ticket }
            anchorEl={ anchorEl }
            menuOpen={ ticketOptionsMenuOpen }
            handleClose={ handleCloseTicketOptionsMenu }
          />
        </>
      ) }
      { ticket.status === "pending" && (
        <>
        <ButtonWithSpinner
          loading={ loading }
          size="small"
          variant="contained"
          color="primary"
          onClick={ e => handleUpdateTicketStatus(e, "open", user?.id) }
        >
          { i18n.t("messagesList.header.buttons.accept") }
        </ButtonWithSpinner>
        <IconButton style={ { margin: '0px 5px 0px 5px' } } onClick={ handleOpenTicketOptionsMenu }>
            <MenuIcon />
          </IconButton>
          <TicketOptionsMenu
            onReturn={ e => handleUpdateTicketStatus(e, "pending", null) }
            onResolve={ e => handleOpenFinishModal() }
            ticket={ ticket }
            anchorEl={ anchorEl }
            menuOpen={ ticketOptionsMenuOpen }
            handleClose={ handleCloseTicketOptionsMenu }
          />
        </>
      ) }
    </div>
  );
};

export default TicketActionButtons;
