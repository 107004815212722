import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from 'tss-react/mui';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from "@mui/material/CircularProgress";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import useWhatsApps from "../../hooks/useWhatsApps";
import avatarPng from "../../assets/Profile-Avatar.png";



const useStyles = makeStyles()((theme) => {
	return {
		root: {
			display: "flex",
			flexWrap: "wrap",
		},
		multFieldLine: {
			display: "flex",
			"& > *:not(:last-child)": {
				marginRight: theme.spacing(1),
			},
		},

		btnWrapper: {
			position: "relative",
		},

		buttonProgress: {
			color: green[500],
			position: "absolute",
			top: "50%",
			left: "50%",
			marginTop: -12,
			marginLeft: -12,
		},
		formControl: {
			margin: "8px 0px 0px 0px",
			minWidth: 155,
		},
		formControlAdminSuper: {
			width: "100%"
		}
	}
});

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
	email: Yup.string().email("Email Invalid").required("Required"),
});

const UserModal = ({ open, onClose, userId }) => {
	const { classes } = useStyles();
	const { user: loggedInUser } = useContext(AuthContext);

	const initialState = {
		name: "",
		email: "",
		password: "",
		profile: "user",
		media: "",
	};

	const [user, setUser] = useState(initialState);
	const [userImage, setUserImage] = useState([]);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [whatsappId, setWhatsappId] = useState(false);
	const {loading, whatsApps} = useWhatsApps();

	useEffect(() => {
		const fetchUser = async () => {
			setUserImage("");
			if (!userId) return;
			try {
				const { data } = await api.get(`/users/${userId}`);
				setUser(prevState => {
					return { ...prevState, ...data };
				});
				setUserImage(data.media);

				const userQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(userQueueIds);
				setWhatsappId(data.whatsappId ? data.whatsappId : '');
			} catch (err) {
				toastError(err);
			}
		};
		fetchUser();
	}, [userId, open]);

	const handleClose = () => {
		onClose();
		setUser(initialState);
	};

	const handleSaveUser = async values => {
		const userData = { ...values, whatsappId, queueIds: selectedQueueIds };
		try {
			if (userId) {
				await api.put(`/users/${userId}`, userData);
				if (loggedInUser.id === userId) {
					// window.location.reload()
				}
			} else {
				await api.post("/users", userData);
			}
			toast.success(i18n.t("userModal.success"), {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	function checkImg() {
		let imagem = ""
		if (userImage) { imagem = userImage; } else { imagem = avatarPng; }
		return imagem;
	}

	const encodeImageFileAsURL = () => {
		const file = document.querySelector('#userImage').files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {

			setUserImage(reader.result);
		};
		reader.onerror = function (error) {
			console.error(error);
		};
	}
	function verify(imagem) {

		let imagemExiste = ""
		if (imagem && userImage) {
			imagemExiste = userImage;
		} else if (imagem && !userImage) {
			imagemExiste = imagem;
		}
		else {
			imagemExiste = userImage;
		}
		return imagemExiste;
	}


	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<div style={{
					display: 'flex',
					justifyContent: 'space-between'
				}}>
					<DialogTitle id="form-dialog-title">
						{userId
							? `${i18n.t("userModal.title.edit")}`
							: `${i18n.t("userModal.title.add")}`}
					</DialogTitle>
				</div>
				<Formik
					initialValues={user}
					enableReinitialize="true"
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						const array = {
							"name": values.name,
							"email": values.email,
							"password": values.password,
							"profile": values.profile,
							"media": verify(values.media),
							"id": values.id,
							"tokenVersion": values.tokenVersion,
							"queues": values.queues,
							"queueIds": values.queueIds,
						}
						setTimeout(() => {
							handleSaveUser(array);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div>
									<div style={{ marginBottom: '25px' }}>
										<div>
											<div className={classes.multFieldLine}>
												<img style={{
													borderRadius: '100px',
													display: 'block',
													marginLeft: 'auto',
													marginRight: 'auto',
													width: '40%'
												}} alt="user" src={checkImg()} />
											</div>
										</div>
										<div style={{
											marginTop: '10px',
											alignItems: 'center',
											display: 'flex',
											justifyContent: 'center'
										}}>
											<Button variant="contained" component="label">
												{i18n.t("userType.translate.sendPhoto")}
												<input hidden onChange={encodeImageFileAsURL} id="userImage" accept="image/*" type="file" />
											</Button>
										</div>
									</div>
									<div>
										<div className={classes.multFieldLine}>

											<Field
												style={{ display: 'none' }}
												as={TextField}
												type="hidden"
												name="media"
											/>
											<Can
												role={loggedInUser.profile}
												perform="user-modal:editProfile"
												yes={() => (
													<Field
														as={TextField}
														label={i18n.t("userModal.form.name")}
														autoFocus
														name="name"
														error={touched.name && Boolean(errors.name)}
														helperText={touched.name && errors.name}
														variant="outlined"
														margin="dense"
														fullWidth
													/>
												)}
											/>
											<Field
												as={TextField}
												label={i18n.t("userModal.form.password")}
												type="password"
												name="password"
												error={touched.password && Boolean(errors.password)}
												helperText={touched.password && errors.password}
												variant="outlined"
												margin="dense"
												fullWidth
											/>
										</div>
										<div className={classes.multFieldLine}>
											<Can
												role={loggedInUser.profile}
												perform="user-modal:editProfile"
												yes={() => (
													<>
													<Field
														as={TextField}
														label={i18n.t("userModal.form.email")}
														name="email"
														error={touched.email && Boolean(errors.email)}
														helperText={touched.email && errors.email}
														variant="outlined"
														margin="dense"
														fullWidth
													/>
													</>
												)}
											/>
											{loggedInUser.profile !== 'adminSuper' &&
												<FormControl
													variant="outlined"
													className={classes.formControl}
													margin="dense"
												>
													<Can
														role={loggedInUser.profile}
														perform="user-modal:editProfile"
														yes={() => (
															<>
																<InputLabel id="profile-selection-input-label">
																	{i18n.t("userModal.form.profile")}
																</InputLabel>

																<Field
																	as={Select}
																	label={i18n.t("userModal.form.profile")}
																	name="profile"
																	labelId="profile-selection-label"
																	id="profile-selection"
																	required
																>
																	<MenuItem value="admin">{i18n.t("userType.translate.admin")}</MenuItem>
																	<MenuItem value="user">{i18n.t("userType.translate.user")}</MenuItem>
																</Field>

															</>
														)}
													/>
												</FormControl>}
										</div>
										{loggedInUser.profile !== 'adminSuper' && <div>
											<FormControl
												variant="outlined"
												margin="dense"
												className={classes.formControlAdminSuper}
											>
											</FormControl>
										</div>
										}
									</div>
								</div>
								{loggedInUser.profile !== 'adminSuper' && <Can
									role={loggedInUser.profile}
									perform="user-modal:editQueues"
									yes={() => (
										<QueueSelect
											selectedQueueIds={selectedQueueIds}
											onChange={values => setSelectedQueueIds(values)}
										/>
									)}
								/>}


								{loggedInUser.profile !== 'adminSuper' && <Can
									role={loggedInUser.profile}
									perform="user-modal:editQueues"
									yes={() => (!loading &&
										<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
											<InputLabel>{i18n.t("userModal.form.whatsapp")}</InputLabel>
											<Field
												as={Select}
												value={whatsappId}
												onChange={(e) => setWhatsappId(e.target.value)}
												label={i18n.t("userModal.form.whatsapp")}
											>
												<MenuItem value={''}>&nbsp;</MenuItem>
												{whatsApps.map((whatsapp) => (
													<MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
												))}
											</Field>
										</FormControl>
									)}
								/>}

							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{userId
										? `${i18n.t("userModal.buttons.okEdit")}`
										: `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div >
	);
};

export default UserModal;