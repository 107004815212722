import React, { useState, useContext } from "react";

import MenuItem from "@mui/material/MenuItem";

import { i18n } from "../../translate/i18n";
import { Menu } from "@mui/material";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";

const MessageOptionsMenu = ({ message, menuOpen, handleClose, anchorEl }) => {
  const { setReplyingMessage } = useContext(ReplyMessageContext);

  const hanldeReplyMessage = () => {
    setReplyingMessage(message);
    handleClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      getcontentanchorel={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={menuOpen}
      onClose={handleClose}
    >
      <MenuItem onClick={hanldeReplyMessage}>
        {i18n.t("messageOptionsMenu.reply")}
      </MenuItem>
    </Menu>
  );
};

export default MessageOptionsMenu;
