import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Box, Card, Input, Stack, Link, Avatar, Alert, Container, Typography, InputAdornment, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import useResponsive from '../../hooks/useResponsive';
import { i18n } from "../../translate/i18n";
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import LogoMeta from '../../assets/icons/TeamChat.png';
import iconPage from '../../assets/icons/live-chat-benefits.png';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Image from '../../components/Image';
import OutlinedInput from '@mui/material/OutlinedInput';
import { AuthContext } from "../../context/Auth/AuthContext";

const RootStyle = styled('div')(({ theme }) => ({
  [ theme.breakpoints.up('md') ]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [ theme.breakpoints.up('md') ]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const useStyles = makeStyles()((theme) => {
  return {
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      // backgroundColor: theme.palette.secondary.main,
      backgroundColor: "transparent",
      width: "150px", // Fix IE 11 issue.
      height: "150px", // Fix IE 11 issue.
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    logo: {
      width: "100%", // Fix IE 11 issue.
    },
  }
});

const Login = () => {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  const { classes } = useStyles();

  const [ user, setUser ] = useState({ email: "", password: "", showPassword: false });

  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [ e.target.name ]: e.target.value });
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  const handleClickShowPassword = () => {
    setUser({ ...user, showPassword: !user.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setUser({ ...user, [ prop ]: event.target.value });
  };

  const handleChange = (prop) => (event) => {
    setUser({ ...user, [ prop ]: event.target.value });
  };

  return (
    <Page title="Login">
      <RootStyle>
        {/*<HeaderStyle>
          <img alt="Logo" src={LogoMeta} style={{ width: '100%', maxWidth: '40px' }} />
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              {i18n.t("login.buttons.notUser")}{' '}
              <Link variant="subtitle2" component={RouterLink} to={'/demo'}>
                {i18n.t("login.buttons.demo")}
              </Link>
            </Typography>
          )}
        </HeaderStyle>*/}

        { mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={ { px: 5, mt: 10, mb: 5 } }>
              { i18n.t("login.welcome") }
            </Typography>
            <Image
              alt="login"
              src={ iconPage }
            />
          </SectionStyle>
        ) }

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={ { mb: 5 } }>
              <Box sx={ { flexGrow: 1 } }>
                <Typography variant="h4" gutterBottom>
                  { i18n.t("login.formTitle") }
                </Typography>
                <Typography sx={ { color: 'text.secondary' } }> { i18n.t("login.details") }</Typography>
              </Box>
            </Stack>

            <form className={ classes.form } noValidate onSubmit={ handlSubmit }>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label={ i18n.t("login.form.email") }
                name="email"
                value={ user.email }
                onChange={ handleChangeInput }
                autoComplete="email"
                autoFocus
              />
              <FormControl fullWidth sx={ { marginTop: 5 } } variant="outlined">
                <InputLabel htmlFor="password">{ i18n.t("login.form.password") }</InputLabel>
                <OutlinedInput
                  id="password"
                  type={ user.showPassword ? 'text' : 'password' }
                  value={ user.password }
                  required
                  name="password"
                  onChange={ handleChange('password') }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={ handleClickShowPassword }
                        onMouseDown={ handleMouseDownPassword }
                        edge="end"
                      >
                        { user.showPassword ? <VisibilityOff /> : <Visibility /> }
                      </IconButton>
                    </InputAdornment>
                  }
                  autoComplete="current-password"
                  label={ i18n.t("login.form.password") }
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={ { marginTop: 40 } }
              >
                { i18n.t("login.buttons.submit") }
              </Button>
            </form>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page >
  );
};

export default Login;
