import React from "react";
import { makeStyles } from 'tss-react/mui';
import useSettings from '../../hooks/useSettings';
import TicketsCalendar from "../../components/TicketsCalendar";
import Page from '../../components/Page';
import { i18n } from "../../translate/i18n";
import { Container, Grid } from '@mui/material';

const useStyles = makeStyles()((theme) => {
  return {
    calendarWrapper: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      overflowY: "hidden",
    },
    ticketsCalendar: {},
  }
});

const Calendar = () => {
  const { classes } = useStyles();
  const { themeStretch } = useSettings();

  return (
    <div style={{ marginTop: '10px' }}>
      <Page title={i18n.t("mainDrawer.listItems.calendar")}>
        <Container spacing={3} maxWidth={false} className={classes.mobileLayout}>
          <Grid container>
            <Grid
              item
              xs={12}
              className={classes.calendarWrapper}
            >
              <TicketsCalendar />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </div>
  );
};

export default Calendar;
