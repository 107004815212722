import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import api from "../../services/api";
//
import { dispatch } from '../store';
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

function getColorBasedOnDueDate(dueDate) {
  const now = new Date();
  const eventDueDate = new Date(dueDate);
  return eventDueDate < now ? "#FF4842" : "#00AB55";
}

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload.tickets.map(ticket => {
        const color = getColorBasedOnDueDate(ticket.dueDate);
        return {
          id: ticket.id.toString(),
          allDay: false,
          textColor: color,
          description: ticket.description,
          start: ticket.dueDate,
          end: ticket.dueDate,
          title: ticket.id.toString(),
          contact: ticket.contact.name,
          stage: ticket.stage,
          tags: ticket.tags,
        };
      });
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    updateTicketSuccess(state, action) {
      const updatedTicket = action.payload;
      const updatedEvents = state.events.map(event => 
        event.id === updatedTicket.id ? updatedTicket : event
      );
      state.events = updatedEvents;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = state.events.filter((event) => event.id !== eventId);
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent, updateTicketSuccess, createEventSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getEvents(user, startDate, endDate) {
  return async () => {
    if (!startDate || !endDate) {
      return;
    }

    dispatch(slice.actions.startLoading());

    try {
      const { profile, queues } = user; 
      const queueIds = JSON.stringify(user.queues.map(queue => queue.id));
      const showAll = profile.toUpperCase() === "ADMIN";
      const statuses = ['pending', 'open'];

      let allTickets = [];

      for (let status of statuses) {
        let pageNumber = 1;
        let hasMore = false;

        do {
          const response = await api.get('/chats', {
            params: {
              queueIds,
              showAll,
              status,
              dueDateStart: startDate,
              dueDateEnd: endDate,
              pageNumber
            },
          });

          if (!response || !response.data || !response.data.tickets) {
            break; // exit loop if no tickets found
          } else {
            allTickets = allTickets.concat(response.data.tickets);
            hasMore = response.data.hasMore;
          }
          
          pageNumber++;
        } while (hasMore);
      }

      dispatch(slice.actions.getEventsSuccess({ tickets: allTickets }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEvent(eventId, updatedEvent) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await api.put(`/chats/${eventId}`, {
        dueDate: updatedEvent.start, 
        description: updatedEvent.description,
        stage: updatedEvent.stage
      });
      
      if(response.data){
        const updatedEvent = {
          ...response.data,
          start: response.data.dueDate,
          end: response.data.dueDate,
          title: response.data.id.toString(),
          contact: response.data.contact.name,
          stage: response.data.stage
        };
        dispatch(slice.actions.updateEventSuccess(updatedEvent));
        toast.success('Chat updated successfully.');
      }
      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateTicket(updatedTicket) {
  return async (dispatch, getState) => {
    try {
      const color = getColorBasedOnDueDate(updatedTicket.dueDate);
      const updatedEvent = {
        id: updatedTicket.id.toString(),
        allDay: false,
        textColor: color,
        description: updatedTicket.description,
        start: updatedTicket.dueDate,
        end: updatedTicket.dueDate,
        title: updatedTicket.id.toString(),
        contact: updatedTicket.contact.name,
        stage: updatedTicket.stage,
        tags: updatedTicket.tags,
      };
      
      const currentEvents = getState().calendar.events;
      const eventExists = currentEvents.find(event => event.id === updatedEvent.id);

      if (eventExists) {
        dispatch(slice.actions.updateTicketSuccess(updatedEvent));
      } else {
        dispatch(slice.actions.createEventSuccess(updatedEvent));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}





// ----------------------------------------------------------------------

export function selectRange(start, end) {
  return async () => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime(),
      })
    );
  };
}
