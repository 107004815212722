import React, { useEffect, useReducer, useState, useContext } from "react";
import { Redirect } from 'react-router-dom';
import { Can } from "../../components/Can";
import openSocket from "socket.io-client";
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  IconButton,
  Paper,
  Table,
  Checkbox,
  Card,
  FormControlLabel,
  TableContainer,
  TableBody,
  TableCell,
  Container,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { DeleteOutline, Edit } from "@mui/icons-material";
import QueueModal from "../../components/QueueModal";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
import { AuthContext } from "../../context/Auth/AuthContext";
import Tooltip from '@mui/material/Tooltip';
import useSettings from '../../hooks/useSettings';

import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Scrollbar from '../../components/Scrollbar';
import Page from '../../components/Page';
import SmartToyIcon from '@mui/icons-material/SmartToy';



const useStyles = makeStyles()((theme) => {
  return {
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
    customTableCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }
});

const reducer = (state, action) => {
  if (action.type === "LOAD_QUEUES") {
    const queues = action.payload;
    const newQueues = [];

    queues.forEach((queue) => {
      const queueIndex = state.findIndex((q) => q.id === queue.id);
      if (queueIndex !== -1) {
        state[ queueIndex ] = queue;
      } else {
        newQueues.push(queue);
      }
    });

    return [ ...state, ...newQueues ];
  }

  if (action.type === "UPDATE_QUEUES") {
    const queue = action.payload;
    const queueIndex = state.findIndex((u) => u.id === queue.id);

    if (queueIndex !== -1) {
      state[ queueIndex ] = queue;
      return [ ...state ];
    } else {
      return [ queue, ...state ];
    }
  }

  if (action.type === "DELETE_QUEUE") {
    const queueId = action.payload;
    const queueIndex = state.findIndex((q) => q.id === queueId);
    if (queueIndex !== -1) {
      state.splice(queueIndex, 1);
    }
    return [ ...state ];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Queues = () => {
  const { classes } = useStyles();

  const [ queues, dispatch ] = useReducer(reducer, []);
  const [ loading, setLoading ] = useState(false);
  const { themeStretch } = useSettings();
  const [ queueModalOpen, setQueueModalOpen ] = useState(false);
  const [ selectedQueue, setSelectedQueue ] = useState(null);
  const [ confirmModalOpen, setConfirmModalOpen ] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/team");
        dispatch({ type: "LOAD_QUEUES", payload: data });

        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    })();
  }, []);



  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("queue", (data) => {
      if ((data.action === "update" || data.action === "create")) {
        dispatch({ type: "UPDATE_QUEUES", payload: data.queue });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_QUEUE", payload: data.queueId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenQueueModal = () => {
    setQueueModalOpen(true);
    setSelectedQueue(null);
  };

  const handleCloseQueueModal = () => {
    setQueueModalOpen(false);
    setSelectedQueue(null);
  };

  const handleEditQueue = (queue) => {
    setSelectedQueue(queue);
    setQueueModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedQueue(null);
  };

  const handleDeleteQueue = async (queueId) => {
    try {
      await api.delete(`/chat/${ queueId }`);
      toast.success(i18n.t("Team deleted successfully!"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (err) {
      toastError(err);
    }
    setSelectedQueue(null);
  };

  return (
		<Can
		role={user.profile}
		perform="admin-page:view"
		yes={() => (
    <>
      <div style={ { marginTop: '65px' } }>
        <ConfirmationModal
          title={
            selectedQueue &&
            `${ i18n.t("queues.confirmationModal.deleteTitle") } ${ selectedQueue.name
            }?`
          }
          open={ confirmModalOpen }
          onClose={ handleCloseConfirmationModal }
          onConfirm={ () => handleDeleteQueue(selectedQueue.id) }
        >
          { i18n.t("queues.confirmationModal.deleteMessage") }
        </ConfirmationModal>
        <QueueModal
          open={ queueModalOpen }
          onClose={ handleCloseQueueModal }
          queueId={ selectedQueue?.id }
        />
        <Page title={ i18n.t("queues.title") }>
          <Container maxWidth={ themeStretch ? false : 'lg' }>
            <HeaderBreadcrumbs
              heading={ i18n.t("queues.title") }
              action={
                <>
                  <Button
                    style={ { margin: 2 } }
                    variant="contained"
                    color="primary"
                    onClick={ handleOpenQueueModal }
                  >
                    { i18n.t("queues.buttons.add") }
                  </Button>
                </>
              }
            />
            <Card>
              <Scrollbar>
                <TableContainer sx={ { minWidth: 500 } } style={ { marginTop: '40px' } }>
                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          { i18n.t("queues.table.name") }
                        </TableCell>
                        <TableCell align="center">
                          { i18n.t("queues.table.color") }
                        </TableCell>
                        <TableCell align="center">
                          { i18n.t("queues.table.actions") }
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        { queues.map((queue) => (
                          <TableRow key={ queue.id }>
                            <TableCell align="left">{ queue.name }</TableCell>
                            <TableCell align="center">
                              <div className={ classes.customTableCell }>
                                <span
                                  style={ {
                                    backgroundColor: queue.color,
                                    width: 20,
                                    height: 20,
                                    borderRadius: '10px',
                                    alignSelf: "center",
                                  } }
                                />
                              </div>
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={ () => handleEditQueue(queue) }
                              >
                                <Edit />
                              </IconButton>

                              <IconButton
                                size="small"
                                onClick={ () => {
                                  setSelectedQueue(queue);
                                  setConfirmModalOpen(true);
                                } }
                              >
                                <DeleteOutline />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )) }
                        { loading && <TableRowSkeleton columns={ 4 } /> }
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
          </Container>
        </Page>
      </div>

    </>
      )}
      no={() => (
        <Redirect to="/chats" />
      )}
    />
  );
};

export default Queues;
