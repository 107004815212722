import React from "react";
import { Drawer } from "@mui/material";
import PropTypes from 'prop-types';
import { TicketContext } from '../TicketContext';
import Ticket from "../Ticket";

const EmbeddedTicket = ({ ticketId, isOpen, onClose }) => {

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right" PaperProps={{ sx: { width: { xs: 1, sm: 480 } } }}>
      <TicketContext.Provider value={ticketId}>
        <Ticket onClose={onClose} onBack={onClose} fromEmbedded={true} />
      </TicketContext.Provider>
    </Drawer>
  );
};

EmbeddedTicket.propTypes = {
  ticketId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default EmbeddedTicket;
