import React, { useContext, useState, useEffect, useReducer, useCallback } from "react";
import { Redirect } from 'react-router-dom';
import { Can } from "../../components/Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import { toast } from "react-toastify";
import openSocket from "socket.io-client";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import TagModal from "../../components/TagModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { Chip, styled, Paper } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import {
  Container,
  Card,
  TableContainer
} from "@mui/material";
import Page from "../../components/Page";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import useSettings from '../../hooks/useSettings';
import Scrollbar from '../../components/Scrollbar';




const useStyles = makeStyles()((theme) => {
  return {
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
    customTableCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }
});

const reducer = (state, action) => {
  if (action.type === "LOAD_TAGS") {
    const tags = action.payload;
    const newTags = [];

    tags.forEach((tag) => {
      const tagIndex = state.findIndex((s) => s.id === tag.id);
      if (tagIndex !== -1) {
        state[tagIndex] = tag;
      } else {
        newTags.push(tag);
      }
    });

    return [...state, ...newTags];
  }

  if (action.type === "UPDATE_TAGS") {
    const tag = action.payload;
    const tagIndex = state.findIndex((s) => s.id === tag.id);

    if (tagIndex !== -1) {
      state[tagIndex] = tag;
      return [...state];
    } else {
      return [tag, ...state];
    }
  }

  if (action.type === "DELETE_TAG") {
    const tagId = action.payload;

    const tagIndex = state.findIndex((s) => s.id === tagId);
    if (tagIndex !== -1) {
      state.splice(tagIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const MainPaper = styled(Paper)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(1),
  overflowY: "scroll",
  ...theme.scrollbarStyles,
}));


const Tags = () => {
	const { user } = useContext(AuthContext);
  const { classes } = useStyles();
  const { themeStretch } = useSettings();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [deletingTag, setDeletingTag] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [tags, dispatch] = useReducer(reducer, []);
  const [tagModalOpen, setTagModalOpen] = useState(false);

  const fetchTags = useCallback(async() => {
    try {
      const { data } = await api.get("/tags/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_TAGS", payload: data.tags });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, [searchParam, pageNumber]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchTags();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, fetchTags]);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("user", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_TAGS", payload: data.tags });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_USER", payload: +data.tagId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenTagModal = () => {
    setSelectedTag(null);
    setTagModalOpen(true);
  };

  const handleCloseTagModal = () => {
    setSelectedTag(null);
    setTagModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditTag = (tag) => {
    setSelectedTag(tag);
    setTagModalOpen(true);
  };

  const handleDeleteTag = async (tagId) => {
    try {
      await api.delete(`/tags/${tagId}`);
      toast.success(i18n.t("tags.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingTag(null);
    setSearchParam("");
    setPageNumber(1);

    dispatch({ type: "RESET" });
    setPageNumber(1);
    await fetchTags();
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
		<Can
		role={user.profile}
		perform="admin-page:view"
		yes={() => (
    <>
      <div style={{ marginTop: '65px' }}>
        <ConfirmationModal
          title={
            deletingTag &&
            `${i18n.t("tags.confirmationModal.deleteTitle")}`
          }
          open={confirmModalOpen}
          onClose={setConfirmModalOpen}
          onConfirm={() => handleDeleteTag(deletingTag.id)}
        >
          {i18n.t("tags.confirmationModal.deleteMessage")}
        </ConfirmationModal>
        <TagModal
          open={tagModalOpen}
          onClose={handleCloseTagModal}
          reload={fetchTags}
          aria-labelledby="form-dialog-title"
          tagId={selectedTag && selectedTag.id}
        />
        <Page title={i18n.t("tags.title")}>
          <Container maxWidth={themeStretch ? false : 'lg'}>
            <HeaderBreadcrumbs
              heading={i18n.t("tags.title")}
              action={
                <>
                  <Button
                    style={{ margin: 2 }}
                    variant="contained"
                    color="primary"
                    onClick={handleOpenTagModal}
                  >
                    {i18n.t("tags.buttons.add")}
                  </Button>
                </>
              }
            />
            <Card>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 500 }} style={{ marginTop: '40px' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">{i18n.t("tags.table.name")}</TableCell>
                        <TableCell align="center">{i18n.t("tags.table.tickets")}</TableCell>
                        <TableCell align="center">{i18n.t("tags.table.actions")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        {tags.map((tag) => (
                          <TableRow key={tag.id}>
                            <TableCell align="center">
                              <Chip
                                variant="outlined"
                                style={{ backgroundColor: tag.color, textShadow: '1px 1px 1px #000', color: 'white' }}
                                label={tag.name}
                                size="small"
                              />
                            </TableCell>
                            <TableCell align="center">{tag.ticketsCount}</TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() => handleEditTag(tag)}
                              >
                                <EditIcon />
                              </IconButton>

                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  setConfirmModalOpen(true);
                                  setDeletingTag(tag);
                                }}
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                        {loading && <TableRowSkeleton columns={4} />}
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
          </Container>
        </Page>
      </div>
    </>
      )}
      no={() => (
        <Redirect to="/chats" />
      )}
    />
  );
};

export default Tags;