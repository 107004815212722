import PropTypes from 'prop-types';
import { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
// @mui
import { Paper, Typography, Box, Avatar, Badge, Button } from '@mui/material';
// components
import KanbanTaskDetails from './KanbanTaskDetails';
import EmbeddedTicket from '../../../components/EmbeddedTicket';
import { useDispatch } from 'react-redux';
import { updateCard} from '../../../redux/slices/kanban'
import { Chip, Tooltip } from '@mui/material';

// ----------------------------------------------------------------------

KanbanTaskCard.propTypes = {
  card: PropTypes.object,
  index: PropTypes.number,
  onDeleteTask: PropTypes.func,
};

export default function KanbanTaskCard({ card, onDeleteTask, index }) {
  const { id, name, contact, user, queue, lastMessage, lastMessageAt, unreadMessages, tags = [] } = card;
  
  const [openDetails, setOpenDetails] = useState(false);
  const [openTicket, setOpenTicket] = useState(false);
  const dispatch = useDispatch();

  const getContrast = (hexcolor) => {
    hexcolor = hexcolor.replace("#", "");
    const r = parseInt(hexcolor.substr(0,2),16);
    const g = parseInt(hexcolor.substr(2,2),16);
    const b = parseInt(hexcolor.substr(4,2),16);
    const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return brightness > 155 ? '#000000' : '#ffffff';
  }

  const handleOpenDetails = (event) => {
    event.stopPropagation(); // prevent the event from bubbling up
    setOpenDetails(true);
  };
  
  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  const handleOpenTicket = (event) => {
    event.stopPropagation(); // prevent the event from bubbling up
    if (card && card.id) {
      setOpenTicket(true);
    } else {
      console.log('card.id is undefined');
    }
  };

  const handleCloseTicket = () => {
    setOpenTicket(false);
  };

  const updateTaskCard = (taskId, updatedFields) => {
    dispatch(updateCard(taskId, updatedFields));
  };

  return (
    <Draggable draggableId={card.id.toString()} index={index}>
      {(provided) => (
        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <Paper
            sx={{
              px: 2,
              py: 3,
              width: 1,
              position: 'relative',
              boxShadow: (theme) => theme.customShadows.z1,
              '&:hover': {
                boxShadow: (theme) => theme.customShadows.z16,
              }
            }}
          >
            <Box onClick={handleOpenTicket} sx={{ cursor: 'pointer' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar src={contact.profilePicUrl} alt={contact.name} sx={{ width: 30, height: 30, mr: 1 }} />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography noWrap variant="subtitle2" gutterBottom>
                    {name}, {contact.name.length > 15 ? `${contact.name.substring(0, 15)}` : contact.name},
                  </Typography>
                  <Typography noWrap variant="subtitle2" gutterBottom sx={{ color: `${queue?.color}` }}>
                    {user?.name}
                  </Typography>
                </Box>
              </Box>
              <Typography color="textSecondary" noWrap>
                "{lastMessage}"
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography color="textSecondary" variant="caption">
                  {new Date(lastMessageAt).toLocaleString()}
                </Typography>
                {unreadMessages > 0 && (
                  <Badge badgeContent={unreadMessages} color="error" />
                )}
                <Button variant="outlined" size="small" onClick={handleOpenDetails}>
                  Update
                </Button>
              </Box>
              <Box sx={{ flexWrap: 'wrap', display: 'flex', alignItems: 'center', mt: 1 }}>
                {tags.slice(0, 6).map((tag, index) => (
                  <Tooltip key={index} title={tag.name}>
                    <Chip
                      label={tag.name.length > 5 ? tag.name.substring(0, 5) + "..." : tag.name}
                      style={{ backgroundColor: tag.color, color: getContrast(tag.color), margin: 1 }}
                      variant="outlined"
                      size="small"
                    />
                  </Tooltip>
                ))}
                {tags.length > 6 &&
                  <Tooltip title={tags.slice(6).map(tag => tag.name).join(', ')}>
                    <Chip
                      label="..."
                      style={{ margin: 1 }}
                      variant="outlined"
                      size="small"
                    />
                  </Tooltip>
                }
              </Box>
            </Box>
          </Paper>

          <KanbanTaskDetails
            card={card}
            isOpen={openDetails}
            onClose={handleCloseDetails}
            onDeleteTask={() => onDeleteTask(card.id)}
            onUpdateTask={updateTaskCard}
          />

          {card.id && 
            <EmbeddedTicket
              ticketId={card.id.toString()}
              isOpen={openTicket}
              onClose={handleCloseTicket}
            />
          }
        </div>
      )}
    </Draggable>
  );
}
