import React, { useContext, useState, useEffect } from "react";
import { Redirect } from 'react-router-dom';
import { Can } from "../../components/Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import openSocket from "socket.io-client";

import { makeStyles } from 'tss-react/mui';
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";
import {
	MenuItem,
	FormControl,
	Box,
	CircularProgress
} from "@mui/material";
import useSettings from '../../hooks/useSettings';

const useStyles = makeStyles()((theme) => {
	return {
		root: {
			display: "flex",
			alignItems: "center",
			padding: theme.spacing(4),
			width: '100%'
		},
		paper: {
			margin: 5,
			justifyContent: 'space-between',
			padding: theme.spacing(2),
			display: "flex",
			alignItems: "center",
		},
		settingOption: {
			marginLeft: "auto",
		},
		margin: {
			margin: theme.spacing(1),
		},
		progress: {
			marginLeft: '40px',
			padding: '1px',
			marginTop: '7px'
		},
		box: {
			padding: '0px 15%'
		}
	}
});

const Settings = () => {
	const { user } = useContext(AuthContext);
	const { classes } = useStyles();
	const { themeStretch } = useSettings();
	const [settings, setSettings] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchSession = async () => {
			setLoading(true)
			try {
				const { data } = await api.get("/settings");
				setSettings(data);
				setLoading(false)
			} catch (err) {
				toastError(err);
			}
			setLoading(false)
		};
		fetchSession();
	}, []);

	useEffect(() => {
		const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

		socket.on("settings", data => {
			if (data.action === "update") {
				setSettings(prevState => {
					const aux = [...prevState];
					const settingIndex = aux.findIndex(s => s.key === data.setting.key);
					aux[settingIndex].value = data.setting.value;
					return aux;
				});
			}
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	const handleChangeSetting = async e => {
		let selectedValue = e.target.value;
		const settingKey = e.target.name;
	
		if (settingKey === "userCreation") {
			selectedValue = selectedValue ? "enabled" : "disabled"; // Adjusted line
		}
		try {
			await api.put(`/settings/${settingKey}`, {
				value: selectedValue,
			});
			toast.success(i18n.t("settings.success"), {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
		} catch (err) {
			toastError(err);
		}
	};

	const getSettingValue = key => {
		const { value } = settings.find(s => s.key === key);
		if (key === "userCreation") {
			return value === "enabled"; // Adjusted line
		}
		return value;
	};

	return (
		<Can
		role={user.profile}
		perform="admin-page:view"
		yes={() => (
	    <div className={classes.root}>
	        <Page className={classes.root} title={i18n.t("settings.title")}>
	            <Container maxWidth={themeStretch ? false : 'lg'}>
	                <HeaderBreadcrumbs
	                    heading={i18n.t("settings.title")}
	                />
	                <div className={classes.box}>
	                    <Paper className={classes.paper}>
	                        <Typography variant="body1">
	                            {i18n.t("settings.settings.userCreation.name")}
	                        </Typography>
	                        <Box sx={{ minWidth: 130 }}>
	                            <FormControl fullWidth>
	                                {loading ?
	                                    <div className={classes.progress} >
	                                        <CircularProgress />
	                                    </div> : <>
										<Select
											name="userCreation"
											value={settings && settings.length > 0 && getSettingValue("userCreation")}
											onChange={handleChangeSetting}
										>
											<MenuItem value={true}>{i18n.t("settings.settings.userCreation.options.enabled")}</MenuItem>
											<MenuItem value={false}>{i18n.t("settings.settings.userCreation.options.disabled")}</MenuItem>
										</Select>
	                                    </>}
	                            </FormControl>
	                        </Box>
	                    </Paper>
	                    <Paper className={classes.paper}>
	                        <TextField
	                            id="api-token-setting"
	                            label="Token Api"
	                            margin="dense"
	                            variant="outlined"
	                            fullWidth
	                            InputProps={{ readOnly: true }}
	                            value={settings && settings.length > 0 && getSettingValue("userApiToken")}
	                        />
	                    </Paper>
	                </div>
	            </Container>
	        </Page>
	    </div>
      )}
      no={() => (
        <Redirect to="/chats" />
      )}
    />
	);
};

export default Settings;
