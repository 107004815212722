import React, { useState, useEffect, useContext, useRef } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import clsx from "clsx";
import EmojiPicker from '../../components/EmojiPicker';
import { makeStyles } from 'tss-react/mui';
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import MoreVert from "@mui/icons-material/MoreVert";
import MoodIcon from "@mui/icons-material/Mood";
import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Cancel";
import ClearIcon from "@mui/icons-material/Clear";
import MicIcon from '@mui/icons-material/Mic';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
  FormControlLabel,
  Hidden,
  Menu,
  MenuItem,
  Switch,
  Button
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import RecordingTimer from "./RecordingTimer";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import useLocalStorage from "../../hooks/useLocalStorage";
import toastError from "../../errors/toastError";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles()((theme) => {
  return {
    mainWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },

    newMessageBox: {
      width: "100%",
      display: "flex",
      padding: "7px",
      alignItems: "center",
    },

    messageInputWrapper: {
      padding: 6,
      marginRight: 7,

      display: "flex",
      borderRadius: 20,
      flex: 1,
      position: "relative",
    },

    messageInput: {
      paddingLeft: 10,
      flex: 1,
      border: "none",
    },

    sendMessageIcons: {
      color: "grey",
    },

    uploadInput: {
      display: "none",
    },

    viewMediaInputWrapper: {
      display: "flex",
      padding: "10px 13px",
      position: "relative",
      justifyContent: "space-between",
      alignItems: "center",

      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },

    emojiBox: {
      position: "absolute",
      bottom: 63,
      width: 40,
      borderTop: "1px solid #e8e8e8",
    },

    circleLoading: {
      opacity: "70%",
      position: "absolute",
      top: "20%",
      left: "50%",
      marginLeft: -12,
    },

    audioLoading: {
      opacity: "70%",
    },

    recorderWrapper: {
      display: "flex",
      alignItems: "center",
      alignContent: "middle",
    },

    cancelAudioIcon: {
      color: "red",
    },

    sendAudioIcon: {
      color: "green",
    },

    replyginMsgWrapper: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 8,
      paddingLeft: 73,
      paddingRight: 7,
    },

    replyginMsgContainer: {
      flex: 1,
      marginRight: 5,
      overflowY: "hidden",
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      borderRadius: "7.5px",
      display: "flex",
      position: "relative",
    },

    replyginMsgBody: {
      padding: 10,
      height: "auto",
      display: "block",
      whiteSpace: "pre-wrap",
      overflow: "hidden",
    },

    replyginContactMsgSideColor: {
      flex: "none",
      width: "4px",
      backgroundColor: "#35cd96",
    },

    replyginSelfMsgSideColor: {
      flex: "none",
      width: "4px",
      backgroundColor: "#6bcbef",
    },

    messageContactName: {
      display: "flex",
      fontWeight: 500,
    },

    messageQuickAnswersWrapper: {
      margin: 0,
      backgroundColor: "#41b3a3",
      position: "absolute",
      bottom: "50px",
      padding: "2px",
      border: "1px solid #CCC",
      left: 0,
      width: "100%",
      "& li": {
        listStyle: "none",
        "& a": {
          display: "block",
          padding: "8px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          maxHeight: "32px",
          "&:hover": {
            background: "#41b3a3",
            cursor: "pointer",
          },
        },
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
  }
});

const MessageInput = ({ ticketStatus, ticketId, droppedFiles }) => {
  const { classes } = useStyles();

  const [ medias, setMedias ] = useState([]);
  const [ inputMessage, setInputMessage ] = useState("");
  const [ showEmoji, setShowEmoji ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ recording, setRecording ] = useState(false);
  const [ quickAnswers, setQuickAnswer ] = useState([]);
  const [ typeBar, setTypeBar ] = useState(false);
  const inputRef = useRef();
  const [ anchorEl, setAnchorEl ] = useState(null);
  const { setReplyingMessage, replyingMessage } =
    useContext(ReplyMessageContext);
  const { user } = useContext(AuthContext);

  const [ signMessage, setSignMessage ] = useLocalStorage("signOption", true);
  const [serviceWindowExpired, setServiceWindowExpired] = useState(false);
  const [templateSent, setTemplateSent] = useState(false);

  useEffect(() => {
    if (droppedFiles) {
      // Spread the dropped files array and add them to the medias state
      setMedias(prevMedias => [...prevMedias, ...droppedFiles]);
    }
  }, [droppedFiles]);

  useEffect(() => {
    if (ticketId === undefined) {
      return;
    }
    const checkServiceWindow = async () => {
      try {
        const { data } = await api.get(`/chats/${ticketId}`);
  
        // Check if the ticket's channel is 'whatsapp_business_account'
        if (data.channel === 'whatsapp_business_account') {
          if (data.lastCustomerMessageAt) {
            const lastCustomerMessageAt = new Date(data.lastCustomerMessageAt);
            const now = new Date();
  
            const diffHours = Math.abs(now - lastCustomerMessageAt) / 36e5;
  
            setServiceWindowExpired(diffHours >= 24);
            // reset the templateSent status if the service window is not expired
            if(diffHours < 24) {
              setTemplateSent(false);
            }
          } else {
            setServiceWindowExpired(false);  // Set to false when lastCustomerMessageAt is null
          }
        } else {
          // If the channel is not 'whatsapp_business_account', set 'serviceWindowExpired' to false
          setServiceWindowExpired(false);
        }
  
      } catch (err) {
        toastError(err);
      }
    };
    checkServiceWindow();
  }, [ticketId]);

  const [ quickEl, setQuickEl ] = useState(null);
  const openQuick = Boolean(quickEl);
  const handleClick = (event) => {
    setQuickEl(event.currentTarget);
  };
  const quickMessagesClose = () => {
    setQuickEl(null);
    setTimeout(() => {
      if (inputMessage === '/') { setInputMessage(""); }
    }, 300);
  };

  useEffect(() => {
    inputRef.current.focus();
  }, [ replyingMessage ]);

  useEffect(() => {
    inputRef.current.focus();
    return () => {
      setInputMessage("");
      setShowEmoji(false);
      setMedias([]);
      setReplyingMessage(null);
    };
  }, [ ticketId, setReplyingMessage ]);

  const handleChangeInput = (e) => {
    setInputMessage(e.target.value);
    handleLoadQuickAnswer(e.target.value);
  };

  const handleQuickAnswersClick = (value) => {
    setInputMessage(value);
    setTypeBar(false);
  };

  const handleAddEmoji = (e) => {
    let emoji = e.native;
    setInputMessage((prevState) => prevState + emoji);
  };

  const handleChangeMedias = (e) => {
    if (!e.target.files) {
      return;
    }
    let selectedMedias = Array.from(e.target.files);
    setMedias(selectedMedias);
  };

  const handleInputPaste = (e) => {

    if (e.clipboardData.files[ 0 ]) {
      setMedias([ e.clipboardData.files[ 0 ] ]);
    }
  };

  const handleUploadMedia = async (e) => {
    setLoading(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append("fromMe", true);
    medias.forEach((media) => {
      formData.append("medias", media);
      formData.append("body", media.name);
    });

    try {
      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setLoading(false);
    setMedias([]);
  };

  const handleRemoveMedia = (mediaToRemove) => {
    setMedias((prevMedias) => prevMedias.filter(media => media !== mediaToRemove));
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() === "") return;
    setLoading(true);

    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: signMessage
        ? `*${ user?.name }:*\n${ inputMessage.trim() }`
        : inputMessage.trim(),
      cleanMsg: inputMessage.trim(),
      quotedMsg: replyingMessage,
    };
    try {
      await api.post(`/messages/${ticketId}`, message);
    } catch (err) {
      toastError(err);
    }

    setInputMessage("");
    setShowEmoji(false);
    setLoading(false);
    setReplyingMessage(null);
  };

  const handleStartRecording = async () => {
    setLoading(true);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await Mp3Recorder.start();
      setRecording(true);
      setLoading(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const handleLoadQuickAnswer = async (value) => {
    if (value && value.indexOf("/") === 0) {
      try {
        const { data } = await api.get("/quickAnswers/", {
          params: { searchParam: inputMessage.substring(1) },
        });
        setQuickAnswer(data.quickAnswers);
        if (data.quickAnswers.length > 0) {
          setTypeBar(true);
        } else {
          setTypeBar(false);
        }
      } catch (err) {
        setTypeBar(false);
      }
    } else {
      setTypeBar(false);
    }
  };

  const setQuickAnswerInput = (message) => {
    setInputMessage(message);
    setTypeBar(false);
    setQuickEl(null);
  }
  const handleUploadAudio = async () => {
    setLoading(true);
    try {
      const [ , blob ] = await Mp3Recorder.stop().getMp3();
      if (blob.size < 10000) {
        setLoading(false);
        setRecording(false);
        return;
      }

      const formData = new FormData();
      const filename = `${ new Date().getTime() }.mp3`;
      formData.append("medias", blob, filename);
      formData.append("body", filename);
      formData.append("fromMe", true);

      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setRecording(false);
    setLoading(false);
  };

  const handleCancelAudio = async () => {
    try {
      await Mp3Recorder.stop().getMp3();
      setRecording(false);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function setInputValue(value) {
    setInputMessage(inputMessage ? inputMessage + value : value);
  }

  const handleMenuItemClick = (event) => {
    setAnchorEl(null);
  };

  const handleServiceWindowButtonClick = async () => {
    const templateData = {
      name: "keepintouch",
    };
    // const templateData = {
    //   name: "sample_movie_ticket_confirmation",
    //   body_variables: ["Movie Name", "Movie Time", "Location", "Seat Number"],
    // };
    // const templateData = {
    //     name: "sample_flight_confirmation",
    //     body_variables: ["Departure City", "Arrival City", "Flight Date"],
    // };
    // Prepare the message object with template details
    const message = {
      read: 1,
      fromMe: true,
      body: templateData.name, // set body as the template name
      isTemplate: true, // Indicate that this is a template message
      templateData: templateData, // Include the templateData in the message
    };
  
    try {
      // Send the message
      await api.post(`/messages/${ticketId}`, message);
      setTemplateSent(true);
    } catch (err) {
      toastError(err);
    }
  };

  const renderReplyingMessage = (message) => {
    return (
      <div className={ classes.replyginMsgWrapper }>
        <div className={ classes.replyginMsgContainer }>
          <span
            className={ clsx(classes.replyginContactMsgSideColor, {
              [ classes.replyginSelfMsgSideColor ]: !message.fromMe,
            }) }
          ></span>
          <div className={ classes.replyginMsgBody }>
            { !message.fromMe && (
              <span className={ classes.messageContactName }>
                { message.contact?.name }
              </span>
            ) }
            { message.body }
          </div>
        </div>
        <IconButton
          aria-label="showRecorder"
          component="span"
          disabled={ loading || ticketStatus !== "open" }
          onClick={ () => setReplyingMessage(null) }
        >
          <ClearIcon className={ classes.sendMessageIcons } />
        </IconButton>
      </div>
    );
  };
  if (medias.length > 0)
    return (
      <Paper elevation={0} square className={classes.viewMediaInputWrapper}>
        <div style={{overflow: 'auto', maxHeight: '100px'}}> {/* adjust the maxHeight as necessary */}
          {medias.map((media, index) => (
            <div key={index}>
              <IconButton
                aria-label="cancel-upload"
                component="span"
                onClick={() => handleRemoveMedia(media)}
              >
                <CancelIcon className={classes.sendMessageIcons} />
              </IconButton>

              {loading ? (
                <div>
                  <CircularProgress className={classes.circleLoading} />
                </div>
              ) : (
                <span>
                  {media.name}
                  {/* <img src={media.preview} alt=""></img> */ }
                </span>
              )}
            </div>
          ))}
        </div>

        <IconButton
          aria-label="send-upload"
          component="span"
          onClick={handleUploadMedia}
          disabled={loading}
        >
          <SendIcon className={classes.sendMessageIcons} />
        </IconButton>
      </Paper>
    );
  else {
    return (
      <Paper square elevation={ 0 } className={ classes.mainWrapper }>
        { replyingMessage && renderReplyingMessage(replyingMessage) }
        <div className={ classes.newMessageBox }>
          {serviceWindowExpired ? (
            templateSent ? (
              <div className={classes.buttonContainer}>
                <Button variant="contained" disabled>
                  Waiting Customer Reply
                </Button>
              </div>
            ) : (
              <div className={classes.buttonContainer}>
                <Button variant="contained" color="primary" onClick={handleServiceWindowButtonClick}>
                  Remind Customer Reply
                </Button>
              </div>
            )
          ) : (
          <>
          <Hidden only={ [ "sm", "xs" ] }>
            <EmojiPicker style={ { padding: '5px', marginLeft: '15px' } } id="emojiPicker" disabled={ loading || recording || ticketStatus !== "open" } value={ '' } setValue={ setInputValue } />
          </Hidden>
          <input
            multiple
            type="file"
            id="upload-button"
            disabled={ loading || recording || ticketStatus !== "open" }
            className={ classes.uploadInput }
            onChange={ handleChangeMedias }
          />
          <label htmlFor="upload-button">
            <IconButton
              aria-label="upload"
              component="span"
              disabled={ loading || recording || ticketStatus !== "open" }
            >
              <AttachFileIcon className={ classes.sendMessageIcons } />
            </IconButton>
          </label>
          <FormControlLabel
            style={ { marginLeft: 0, marginRight: 7, color: "gray" } }
            label={ i18n.t("messagesInput.signMessage") }
            labelPlacement="start"
            control={
              <Switch
                size="small"
                checked={ signMessage }
                onChange={ (e) => {
                  setSignMessage(e.target.checked);
                } }
                name="showAllTickets"
                color="primary"
              />
            }
          />
          <div className={ classes.messageInputWrapper }>
            <InputBase
              inputRef={ (input) => {
                input && input.focus();
                input && (inputRef.current = input);
              } }
              className={ classes.messageInput }
              placeholder={
                ticketStatus === "open"
                  ? i18n.t("messagesInput.placeholderOpen")
                  : i18n.t("messagesInput.placeholderClosed")
              }
              multiline
              maxRows={ 5 }
              value={ inputMessage }
              onChange={ handleChangeInput }
              disabled={ recording || loading || ticketStatus !== "open" }
              onPaste={ (e) => {
                ticketStatus === "open" && handleInputPaste(e);
              } }
              onKeyPress={ (e) => {
                if (loading || e.shiftKey) return;
                else if (e.key === "Enter") {
                  handleSendMessage();
                }
              } }
            />
            {typeBar ? (
              <ul className={classes.messageQuickAnswersWrapper}>
                {quickAnswers.map((value, index) => {
                  return (
                    <li
                      className={classes.messageQuickAnswersWrapperItem}
                      key={index}
                    >
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a onClick={() => handleQuickAnswersClick(value.message)}>
                        {`${value.shortcut} - ${value.message}`}
                      </a>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div></div>
            )}
          </div>
          { inputMessage ? (
            <IconButton
              aria-label="sendMessage"
              component="span"
              onClick={ handleSendMessage }
              disabled={ loading }
            >
              <SendIcon className={ classes.sendMessageIcons } />
            </IconButton>
          ) : recording ? (
            <div className={ classes.recorderWrapper }>
              <IconButton
                aria-label="cancelRecording"
                component="span"
                fontSize="large"
                disabled={ loading }
                onClick={ handleCancelAudio }
              >
                <HighlightOffIcon className={ classes.cancelAudioIcon } />
              </IconButton>
              { loading ? (
                <div>
                  <CircularProgress className={ classes.audioLoading } />
                </div>
              ) : (
                <RecordingTimer />
              ) }

              <IconButton
                aria-label="sendRecordedAudio"
                component="span"
                onClick={ handleUploadAudio }
                disabled={ loading }
              >
                <CheckCircleOutlineIcon className={ classes.sendAudioIcon } />
              </IconButton>
            </div>
          ) : (
            <IconButton
              aria-label="showRecorder"
              component="span"
              disabled={ loading || ticketStatus !== "open" }
              onClick={ handleStartRecording }
            >
              <MicIcon className={ classes.sendMessageIcons } />
            </IconButton>
          ) }
          </>
        )}
        </div>
      </Paper >
    );
  }
};

export default MessageInput;
