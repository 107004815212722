import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import { Stack, Typography } from '@mui/material';

// ----------------------------------------------------------------------

KanbanColumnToolBar.propTypes = {
  columnName: PropTypes.string,
};

export default function KanbanColumnToolBar({ columnName }) {
  const [value, setValue] = useState(columnName);

  useEffect(() => {
    setValue(columnName);
  }, [columnName]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ pt: 3 }}>
        <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: 'fontWeightBold' }}
        >
          {value}
        </Typography>
      </Stack>
    </>
  );
}
