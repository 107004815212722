import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";

import { makeStyles } from 'tss-react/mui';
import { green } from "@mui/material/colors";
import { IconButton } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";
import { Can } from "../Can";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import { Tooltip, MenuItem, Button, Box } from "@mui/material";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
// import BadgeStatus from '../../components/BadgeStatus';

import { styled } from '@mui/material/styles';

import VisibilityIcon from '@mui/icons-material/Visibility';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import TelegramIcon from '@mui/icons-material/Telegram';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import FacebookIcon from '@mui/icons-material/Facebook';
import VideocamIcon from '@mui/icons-material/Videocam';
import MicIcon from '@mui/icons-material/Mic';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const useStyles = makeStyles()((theme) => {
	return {
		ticket: {
			position: "relative",
		},

		pendingTicket: {
			cursor: "unset",
		},

		noTicketsDiv: {
			display: "flex",
			height: "100px",
			margin: 40,
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
		},

		noTicketsText: {
			textAlign: "center",
			color: "rgb(104, 121, 146)",
			fontSize: "14px",
			lineHeight: "1.4",
		},

		noTicketsTitle: {
			textAlign: "center",
			fontSize: "16px",
			fontWeight: "600",
			margin: "0px",
		},

		contactNameWrapper: {
			display: "flex",
			justifyContent: "space-between",
		},

		lastMessageTime: {
			justifySelf: "flex-end",
		},

		closedBadge: {
			alignSelf: "center",
			justifySelf: "flex-end",
			marginRight: 32,
			marginLeft: "auto",
		},

		contactLastMessage: {
			paddingRight: 20,
		},

		newMessagesCount: {
			alignSelf: "center",
			marginRight: 8,
			marginLeft: "auto",
		},

		badgeStyle: {
			color: "white",
			backgroundColor: green[500],
		},

		acceptButton: {
			position: "absolute",
			left: "50%",
		},

		ticketQueueColor: {
			flex: "none",
			width: "8px",
			height: "100%",
			position: "absolute",
			top: "0%",
			left: "0%",
		},

		userTag: {
			position: "absolute",
			marginRight: 5,
			right: 5,
			bottom: 5,
			background: "#41b3a3",
			color: "#ffffff",
			border: "1px solid #CCC",
			padding: 1,
			paddingLeft: 5,
			paddingRight: 5,
			borderRadius: 10,
			fontSize: "0.9em"
		},
	}
});

const SmallAvatar = styled(Avatar)(({ theme }) => ({
	width: 22,
	height: 22,
	border: `2px solid ${theme.palette.background.paper}`,
}));

const TicketListItem = ({ ticket }) => {
	const { classes } = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { ticketId } = useParams();
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleAcceptTicket = async id => {
		setLoading(true);
		try {
			await api.put(`/chats/${id}`, {
				status: "open",
				userId: user?.id
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
		history.push(`/chats/${id}`);
	};

	const handleSelectTicket = id => {
		history.push(`/chats/${id}`);
	};

	// const renderChannel = (channel) => {
	// 	let icon = '';

	// 	switch (channel) {
	// 		case 'whatsapp': icon = <WhatsAppIcon style={{ color: '#0dc242' }} />; break;
	// 		case 'telegram': icon = <TelegramIcon style={{ color: '#3390ec' }} />; break;
	// 		case 'instagram': icon = <InstagramIcon style={{ color: '#fe0079' }} />; break;
	// 		case 'messenger': icon = <FacebookIcon style={{ color: '#0573e7' }} />; break;
	// 	}

	// 	return icon;
	// }


	const verifyTypeOfMessage = message => {

		let messageFormated = ''
		if (message.includes('MEDIA_VALUE_')) {
			let renderType = message.split('[[')[1].split(']]')[0]
			if (renderType === 'video') {
				messageFormated = <div style={{ display: 'flex', alignItems: 'center' }}><VideocamIcon fontSize="small" /><p style={{ marginLeft: 5 }}>{i18n.t("userType.translate.video")}</p></div>
			}
			if (renderType === 'image') {
				messageFormated = <div style={{ display: 'flex', alignItems: 'center' }}><CameraAltIcon fontSize="small" /><p style={{ marginLeft: 5 }}>{i18n.t("userType.translate.photo")}</p></div>
			}
			if (renderType === 'audio') {
				messageFormated = <div style={{ display: 'flex', alignItems: 'center' }}><MicIcon fontSize="small" /><p style={{ marginLeft: 5 }}>{i18n.t("userType.translate.audio")}</p></div>
			}
			if (renderType === 'application') {
				messageFormated = <div style={{ display: 'flex', alignItems: 'center' }}><AttachFileIcon fontSize="small" /><p style={{ marginLeft: 5 }}>{i18n.t("userType.translate.file")}</p></div>
			}
		} else {
			messageFormated = message
		}

		return messageFormated;
	}


	const firstWordInCapsLock = text => {
		if (!text) return "";
		return text[0].toUpperCase() + text.substring(1)
	};

	return (
		<>
			<MenuItem key={ticket.id}
				selected={ticket.id === ticketId}
				onClick={e => {
					if (ticket.status === "pending") return;
					handleSelectTicket(ticket.id);
				}}
			>
				<Tooltip
					arrow
					placement="right"
					title={ticket.queue?.name || "No Team"}
				>
					<Box component="span"
						style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
						className={classes.ticketQueueColor}
					/>
				</Tooltip>
				<ListItemAvatar sx={{ position: 'relative' }}>
					<Badge
						overlap="circular"
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						// badgeContent={<Tooltip title={firstWordInCapsLock(ticket.channel)} placement="right">{renderChannel(ticket.channel)}</Tooltip>}
					>
						<Avatar src={ticket.contact.profilePicUrl} />
					</Badge>
				</ListItemAvatar>
				<ListItemText
					primaryTypographyProps={{ typography: 'subtitle2', mb: 0.25 }}
					secondaryTypographyProps={{ typography: 'caption' }}
					primary={
						<Box component="span" style={{
							display: 'flex',
							justifyContent: 'space-between'
						}}>
							<Typography
								noWrap
								component="span"
								variant="body2"
								color="textPrimary"
							>
								{`${ticket.contact.name.substring(0, 10)}${ticket.contact.name.length > 10 ? '...' : ''} # ${ticket.id}`}
							</Typography>
							{ticket.contact.quotation && (
								<Box component="div" style={{ position: 'absolute' }}>
									<Badge
										style={{
											alignSelf: "center",
											justifySelf: "flex-end",
											marginRight: 32,
											marginLeft: "200px"
										}}
										badgeContent={ticket.contact.quotation}
										color="primary"
									/>
								</Box>
							)}
							{ticket.lastMessage && (
								<Typography
									style={{ marginLeft: 5 }}
									component="span"
									variant="body2"
									color="textSecondary"
								>
									{ticket.lastMessageAt ? (
									isSameDay(parseISO(ticket.lastMessageAt), new Date()) ? (
										<>{format(parseISO(ticket.lastMessageAt), "HH:mm")}</>
									) : (
										<>{format(parseISO(ticket.lastMessageAt), "dd/MM/yyyy")}</>
									)
									) : (
									<>No Date</>
									)}
								</Typography>
							)}
							{ticket.status === "closed" && (
								<Box component="div" style={{ position: 'absolute' }}>
									<Badge
										style={{
											alignSelf: "center",
											justifySelf: "flex-end",
											marginRight: 32,
											marginLeft: "200px"
										}}
										badgeContent={i18n.t("userType.translate.closed")}
										color="primary"
									/>
								</Box>
							)}
							{ticket.status === "pending" && (
								<Box 
									component="div" 
									style={{ 
										position: 'absolute', right: ticket.status === "pending" ? '30%' : '5%', bottom: '45%'
									}}
								>
									<Button
										variant="contained" size="small"
										onClick={e => handleAcceptTicket(ticket.id)}
										loading={loading}
									>
										{i18n.t("ticketsList.buttons.accept")}
									</Button>
								</Box>
							)}
						</Box>
					}
					secondary={
						<Box component="span" className={classes.contactNameWrapper}>
							<Typography
								noWrap
								component="span"
								variant="body2"
								color="textSecondary"
							>
								{ticket.lastMessage ? (
									<div style={{
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										width: '250px'
									}}>{verifyTypeOfMessage(ticket.lastMessage)}</div>
								) : (
									<br />
								)}
							</Typography>
							{ticket.whatsappId && (
								<div className={classes.userTag} 
								title={i18n.t("ticketsList.connectionTitle")}>{ticket.whatsapp?.name}
								</div>
							)}
							{ticket.channel === "facebook" && !ticket.whatsappId &&(
								<div className={classes.userTag} 
								title={i18n.t("ticketsList.channelTitle")}>{"facebook"}
								</div>
							)}
							{ticket.channel === "instagram" && !ticket.whatsappId &&(
								<div className={classes.userTag} 
								title={i18n.t("ticketsList.channelTitle")}>{"instagram"}
								</div>
							)}
							{ticket.channel === "whatsapp_business_account" && !ticket.whatsappId &&(
								<div className={classes.userTag} 
								title={i18n.t("ticketsList.channelTitle")}>{"0377839887"}
								</div>
							)}
							<Box component="div" style={{ position: 'absolute', right: ticket.status === "pending" ? '20%' : '5%', bottom: '0%' }}>
								<Box component="div" style={{ display: 'flex', alignItems: 'center' }}>
									{ticket.status === "pending" && 
										<Can 
											role={user.profile} 
											perform="ticketlist-item:viewTicket" 
											yes={() => (
											<IconButton onClick={e => {
												handleSelectTicket(ticket.id);
											}}>
												<VisibilityIcon />
											</IconButton>
											)}
										/>
										}
									<Badge
										style={{ margin: ticket.status === "pending" ? '0px 35px 20px 10px' : '0px 8px 15px 0px' }}
										className={classes.newMessagesCount}
										badgeContent={ticket.unreadMessages}
										classes={{
											badge: classes.badgeStyle,
										}}
									/>
								</Box>
							</Box>
						</Box>
					}
				/>

			</MenuItem>
			<Divider style={{
				listStyleType: 'none',
				marginTop: '0px',
				marginBottom: '0px'
			}} variant="inset" component="li" />
		</>
	);
};

export default TicketListItem;
