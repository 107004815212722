import React from 'react';
import Select from 'react-select';

const channelOptions = [
  { value: "whatsapp", label: "WhatsApp" },
  // { value: "telegram", label: "Telegram" },
  { value: "facebook", label: "Facebook" },
  { value: 'whatsapp_business_account', label: 'Business Account' },
];

const ChannelSelect = ({ selectedChannel, handleChannelChange, theme }) => {
  const selectedOption = channelOptions.find(
    (option) => option.value === selectedChannel[0]
  );

  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderColor: theme.palette.divider,
      '&:hover': {
        borderColor: theme.palette.text.secondary,
      },
      zIndex: 3
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      zIndex: 3,
      backgroundColor: isSelected
        ? theme.palette.primary.main
        : isFocused
        ? theme.palette.action.hover
        : null,
      color: theme.palette.text.primary,
    }),
    singleValue: (styles) => ({ ...styles, color: theme.palette.text.primary }),
    menu: (styles) => ({ ...styles, backgroundColor: theme.palette.background.paper }),
  };

  return (
    <Select
      options={channelOptions}
      value={selectedOption}
      onChange={handleChannelChange}
      placeholder="Filter by channel"
      isClearable
      styles={customStyles}
    />
  );
};

export default ChannelSelect;
