import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';
//
import { useState, useRef, useEffect, useContext } from 'react';
import { AuthContext } from "../../context/Auth/AuthContext";
// @mui
import { Dialog, DialogContent, Box, Typography, Card, Button, Container, DialogTitle } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getEvents, openModal, closeModal, updateEvent, selectEvent, selectRange, updateTicket } from '../../redux/slices/calendar';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
// layouts
import Layout from '../../layout';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { DialogAnimate } from '../../components/animate';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import { CalendarForm, CalendarStyle, CalendarToolbar } from '../../sections/@dashboard/calendar';
import EmbeddedTicket from '../../components/EmbeddedTicket';
import { styled } from '@mui/system';
import openSocket from "socket.io-client";

// ----------------------------------------------------------------------

Calendar.getLayout = function getLayout(page) {
  return <Layout>{page}</Layout>;
};

// ----------------------------------------------------------------------

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const selectedEventSelector = (state) => {
  const { events, selectedEventId } = state.calendar;
  if (selectedEventId) {
    return events.find((_event) => _event.id === selectedEventId);
  }
  return null;
};

export default function Calendar() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const isDesktop = useResponsive('up', 'sm');
  const calendarRef = useRef(null);
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState(isDesktop ? 'dayGridMonth' : 'listWeek');
  const [openTicket, setOpenTicket] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const selectedEvent = useSelector(selectedEventSelector);
  const { events, isOpenModal, selectedRange } = useSelector((state) => state.calendar);
  const [openEventModal, setOpenEventModal] = useState(false);

  const showAll = user?.profile === 'admin';
  const selectedQueueIds = user?.queues?.map(queue => queue.id) || [];

  // useEffect(() => {
  //   if (user) {
  //     dispatch(getEvents(user));
  //   }
  // }, [dispatch, user]);

useEffect(() => {
  const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

  const shouldUpdateTicket = ticket =>
    (!ticket.userId || ticket.userId === user?.id || showAll) &&
    (!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1);

  const notBelongsToUserQueues = ticket =>
    ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

  socket.on('connect', () => {
    socket.emit("joinTickets", 'pending');
    socket.emit("joinTickets", 'open');
  });

  socket.on('ticket', data => {
    if (data.action === 'update' && shouldUpdateTicket(data.ticket)) {
      dispatch(updateTicket(data.ticket));
    }
    if (data.action === 'updateTags' && shouldUpdateTicket(data.ticket)) {
      dispatch(updateTicket(data.ticket));
    }
  });

  // socket.on('appMessage', data => {
  //   // if (data.action === 'create') {
  //   //   dispatch(updateMessage(data.ticket));
  //   // }
  // });

  socket.on('disconnect', () => {
  });

  return () => {
    socket.disconnect();
  }
}, []);

useEffect(() => {
  const calendarEl = calendarRef.current;
  if (calendarEl) {
    const calendarApi = calendarEl.getApi();
    const newView = 'dayGridMonth';
    calendarApi.changeView(newView);
    setView(newView);
  }
}, []);


const handleDatesSet = ({ startStr, endStr, view }) => {
  if (startStr && endStr && view.type === 'dayGridMonth') {
    dispatch(getEvents(user, startStr, endStr));
  } else {
  }
};


  const handleClickToday = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleChangeView = (newView) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const handleSelectRange = (arg) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.unselect();
    }
    dispatch(selectRange(arg.start, arg.end));
  };

  const handleSelectEvent = (info) => {
    setSelectedTicketId(info.event.id.toString());
    setOpenTicket(true); // Open the EmbeddedTicket
    info.jsEvent.preventDefault();
  };

  const handleResizeEvent = async ({ event }) => {
    try {
      dispatch(
        updateEvent(event.id, {
          start: event.start,
          end: event.end,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleDropEvent = async ({ event }) => {
    try {
      dispatch(
        updateEvent(event.id, {
          start: event.start,
          end: event.end,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddEvent = () => {
    dispatch(openModal());
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const renderEventContent = (eventInfo) => {
    let startTime = eventInfo.timeText.split(" - ")[0];
    let contactName = eventInfo.event.extendedProps.contact;

    // Create a mapping for stages to the display names
    const stageMapping = {
      'companyinside': 'Company Inside',
      'draft': 'Inquiry',
      'waitingprice': 'Waiting Price',
      'sendquotation': 'Send Quotation',
      'followup': 'Follow Up',
      'waitingpayment': 'Waiting Payment',
      'production': 'Production',
      'delivery': 'Delivery'
    };

    let stage = stageMapping[eventInfo.event.extendedProps.stage] || eventInfo.event.extendedProps.stage; 

    let displayedContactName = contactName.length > 15 
      ? contactName.substring(0, 15) + '...' 
      : contactName;

    return {
      html: `
        <div style="width: 100%; height: 100%; overflow: hidden;" onclick="handleClick(event, this)">

          <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-height: 20px;">
            <i><span class="fc-event-title">${startTime} #<b>${eventInfo.event.title}</b> - ${stage}</span></i>
          </div>
          <div style="display: flex; align-items: center; justify-content: space-between;">
            <p style="margin: 0; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 70%;">${displayedContactName}</p>
            <button class="edit-event-button" data-id="${eventInfo.event.id}" style="pointer-events: auto; position: relative; z-index: 1; background: none; border: 1px solid #FA8072; color: #FA8072; padding: 1px 6px; text-align: center; text-decoration: none; display: inline-block; font-size: 0.7rem; margin: 0; cursor: pointer; font-weight: bold; border-radius: 5px;">
              Update
            </button>
          </div>
          <div class="ticket-overlay" data-id="${eventInfo.event.id}" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; pointer-events: auto;"></div>
        </div>
      `
    };
  };
// Delegate click events inside event content
window.handleClick = function(event, element) {
  // Event content was clicked
  if (event.target.classList.contains("edit-event-button")) {
    // Edit button was clicked
    event.stopPropagation();
    dispatch(selectEvent(event.target.dataset.id)); // Dispatch select event
    dispatch(openModal()); // Open the CalendarForm
  } else if (event.target.classList.contains("ticket-overlay")) {
    // Ticket overlay was clicked
    event.stopPropagation();
    setSelectedTicketId(event.target.dataset.id.toString());
    setOpenTicket(true); // Open the EmbeddedTicket
  }
};


  return (
    <Page title="Calendar">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading="Calendar"
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Calendar' }]}
        />

        <Card>
          <CalendarStyle>
            <CalendarToolbar
              date={date}
              view={view}
              onNextDate={handleClickDateNext}
              onPrevDate={handleClickDatePrev}
              onToday={handleClickToday}
              onChangeView={handleChangeView}
            />
            <FullCalendar
              weekends
              editable
              droppable
              selectable={false}
              events={events}
              ref={calendarRef}
              rerenderDelay={10}
              initialDate={date}
              initialView={view}
              dayMaxEventRows={3}
              eventDisplay="block"
              headerToolbar={false}
              eventResizableFromStart
              select={handleSelectRange}
              eventDrop={handleDropEvent}
              eventClick={handleSelectEvent}
              eventResize={handleResizeEvent}
              height={isDesktop ? 720 : 'auto'}
              plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
              datesSet={handleDatesSet}
              eventContent={renderEventContent}
              eventDidMount={(info) => {
                info.el.style.pointerEvents = 'none'; // Disable pointer events on the event
                  let editButton = info.el.querySelector(".edit-event-button");
                  editButton.addEventListener('click', (e) => {
                    e.stopPropagation();
                    dispatch(selectEvent(editButton.dataset.id)); // Dispatch select event
                    dispatch(openModal()); // Open the CalendarForm
                  }, false);
                  
                  let ticketOverlay = info.el.querySelector(".ticket-overlay");
                  ticketOverlay.addEventListener('click', (e) => {
                    e.stopPropagation();
                    setSelectedTicketId(ticketOverlay.dataset.id.toString());
                    setOpenTicket(true); // Open the EmbeddedTicket
                  }, false);
                }}
            />
          </CalendarStyle>
        </Card>

        <DialogAnimate open={isOpenModal} onClose={handleCloseModal}>
          <DialogTitle>{selectedEvent ? 'Edit Event' : 'Add Event'}</DialogTitle>
          <CalendarForm event={selectedEvent || {}} range={selectedRange} onCancel={handleCloseModal} />
        </DialogAnimate>

        {selectedTicketId && (
          <EmbeddedTicket
            ticketId={selectedTicketId}
            isOpen={openTicket}
            onClose={() => setOpenTicket(false)}
          />
        )}
      </Container>
    </Page>
  );
}
