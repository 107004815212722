import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from 'tss-react/mui';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CircularProgress from "@mui/material/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginRight: theme.spacing(1),
      flex: 1,
    },

    extraAttr: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    btnWrapper: {
      position: "relative",
    },

    buttonProgress: {

      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  }
});

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email"),
  quotation: Yup.string()
    .matches(/^QT-[0-9][0-9][0-9][0-9][0-9]+(-AMF|-AMK|-PB|-ASH)$/, "Invalid Format, Should like QT-00001-AMF, 5 digital Number, case sensitive")
});

const ContactModal = ({ open, onClose, contactId, initialValues, onSave }) => {
  const { classes } = useStyles();
  const isMounted = useRef(true);

  const { user: loggedInUser } = useContext(AuthContext);

  const initialState = {
    name: "",
    number: "",
    email: "",
    quotation: "",
  };

  const [ contact, setContact ] = useState(initialState);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchContact = async () => {
      if (initialValues) {
        setContact(prevState => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!contactId) return;

      try {
        const { data } = await api.get(`/contacts/${ contactId }`);
        if (isMounted.current) {
          setContact(data);
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchContact();
  }, [ contactId, open, initialValues ]);

  const handleClose = () => {
    onClose();
    setContact(initialState);
  };

  const handleSaveContact = async values => {
    try {
      if (contactId) {
        await api.put(`/contacts/${ contactId }`, values);
        handleClose();
      } else {
        const { data } = await api.post("/contacts", values);
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("contactModal.success"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={ classes.root }>
      <Dialog open={ open } onClose={ handleClose } maxWidth="lg" scroll="paper">
        <DialogTitle id="form-dialog-title">
          { contactId
            ? `${ i18n.t("contactModal.title.edit") }`
            : `${ i18n.t("contactModal.title.add") }` }
        </DialogTitle>
        <Formik
          initialValues={ contact }
          enableReinitialize={ true }
          validationSchema={ ContactSchema }
          onSubmit={ (values, actions) => {
            setTimeout(() => {
              handleSaveContact(values);
              actions.setSubmitting(false);
            }, 400);
          } }
        >
          { ({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <Typography variant="subtitle1" gutterBottom>
                  { i18n.t("contactModal.form.mainInfo") }
                </Typography>
                <Field
                  as={ TextField }
                  label={ i18n.t("contactModal.form.name") }
                  name="name"
                  autoFocus
                  error={ touched.name && Boolean(errors.name) }
                  helperText={ touched.name && errors.name }
                  variant="outlined"
                  margin="dense"
                  className={ classes.textField }
                />
                <Field
                  as={TextField}
                  label={i18n.t("contactModal.form.number")}
                  name="number"
                  error={touched.number && Boolean(errors.number)}
                  helperText={touched.number && errors.number}
                  placeholder="6"
                  variant="outlined"
                  margin="dense"
                  inputProps={{
                    readOnly: true,
                  }}
                />
                <div>
                  <Field
                    as={ TextField }
                    label={ i18n.t("contactModal.form.quotation") }
                    name="quotation"
                    error={touched.quotation && Boolean(errors.quotation)}
                    helperText={ touched.quotation && errors.quotation }
                    placeholder="QT-00001-AMF"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                  />
                </div>
                <div>
                  <Field
                    as={ TextField }
                    label={ i18n.t("contactModal.form.email") }
                    name="email"
                    error={ touched.email && Boolean(errors.email) }
                    helperText={ touched.email && errors.email }
                    placeholder="Email address"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                  />
                </div>
                <Typography
                  style={ { marginBottom: 8, marginTop: 12 } }
                  variant="subtitle1"
                >
                  { i18n.t("contactModal.form.extraInfo") }
                </Typography>

                <FieldArray name="extraInfo">
                  { ({ push, remove }) => (
                    <>
                      { values.extraInfo &&
                        values.extraInfo.length > 0 &&
                        values.extraInfo.map((info, index) => (
                          <div
                            className={ classes.extraAttr }
                            key={ `${ index }-info` }
                          >
                            <Field
                              as={ TextField }
                              label={ i18n.t("contactModal.form.extraName") }
                              name={ `extraInfo[${ index }].name` }
                              variant="outlined"
                              margin="dense"
                              className={ classes.textField }
                            />
                            <Field
                              as={ TextField }
                              label={ i18n.t("contactModal.form.extraValue") }
                              name={ `extraInfo[${ index }].value` }
                              variant="outlined"
                              margin="dense"
                              className={ classes.textField }
                            />
                            <IconButton
                              size="small"
                              onClick={ () => remove(index) }
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          </div>
                        )) }
                      <div className={ classes.extraAttr }>
                        <Button
                          style={ { flex: 1, marginTop: 8 } }
                          variant="outlined"
                          color="primary"
                          onClick={ () => push({ name: "", value: "" }) }
                        >
                          { `+ ${ i18n.t("contactModal.buttons.addExtraInfo") }` }
                        </Button>
                      </div>
                    </>
                  ) }
                </FieldArray>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={ handleClose }
                  color="secondary"
                  disabled={ isSubmitting }
                  variant="outlined"
                >
                  { i18n.t("contactModal.buttons.cancel") }
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={ isSubmitting }
                  variant="contained"
                  className={ classes.btnWrapper }
                >
                  { contactId
                    ? `${ i18n.t("contactModal.buttons.okEdit") }`
                    : `${ i18n.t("contactModal.buttons.okAdd") }` }
                  { isSubmitting && (
                    <CircularProgress
                      size={ 24 }
                      className={ classes.buttonProgress }
                    />
                  ) }
                </Button>
              </DialogActions>
            </Form>
          ) }
        </Formik>
      </Dialog>
    </div>
  );
};

export default ContactModal;
