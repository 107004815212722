import React, { useState, useRef, useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import openSocket from "socket.io-client";
import useSound from "use-sound";

import Scrollbar from '../../components/Scrollbar';
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Avatar, Typography, ListItemText, ListItemAvatar, MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Badge from "@mui/material/Badge";
import ChatIcon from "@mui/icons-material/Chat";
import Iconify from '../../components/Iconify';
import TicketListItem from "../TicketListItem";
import { i18n } from "../../translate/i18n";
import useTickets from "../../hooks/useTickets";
import alertSound from "../../assets/sound.mp3";
import { AuthContext } from "../../context/Auth/AuthContext";

import MenuPopover from '../../components/MenuPopover';

const useStyles = makeStyles()((theme) => {
  return {
    tabContainer: {
      overflowY: "auto",
      maxHeight: 400,
      ...theme.scrollbarStyles,
    },
    popoverPaper: {
      width: "100%",
      maxWidth: 400,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
      [ theme.breakpoints.down("sm") ]: {
        maxWidth: 270,
      },
    },
    noShadow: {
      boxShadow: "none !important",
    },
  }
});


const NotificationsPopOver = () => {
  const { classes } = useStyles();
  const { ticketId } = useParams();

  const history = useHistory();
  const { user } = useContext(AuthContext);
  const ticketIdUrl = +history.location.pathname.split("/")[ 2 ];
  const ticketIdRef = useRef(ticketIdUrl);
  const anchorEl = useRef();
  const [ isOpen, setIsOpen ] = useState(false);
  const [ notifications, setNotifications ] = useState([]);
  const { profile, queues } = user;

  const [ , setDesktopNotifications ] = useState([]);

  const { tickets } = useTickets({ withUnreadMessages: "true" });
  const [ play ] = useSound(alertSound);
  const soundAlertRef = useRef();

  const historyRef = useRef(history);


  useEffect(() => {
    soundAlertRef.current = play;

    if (!("Notification" in window)) {
      console.log("This browser doesn't support notifications");
    } else {
      Notification.requestPermission();
    }
  }, [play]);

  useEffect(() => {
    setNotifications(tickets);
  }, [tickets]);

  useEffect(() => {
    ticketIdRef.current = ticketIdUrl;
  }, [ticketIdUrl]);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);
    const queueIds = queues.map((q) => q.id);

    socket.on("connect", () => {
      socket.emit("joinNotification")
    });

    socket.on("ticket", data => {
      if (data.action === "updateUnread" || data.action === "delete") {
        setNotifications(prevState => {
          const ticketIndex = prevState.findIndex(t => t.id === data.ticketId);
          if (ticketIndex !== -1) {
            prevState.splice(ticketIndex, 1);
            return [ ...prevState ];
          }
          return prevState;
        });

        setDesktopNotifications(prevState => {
          const notfiticationIndex = prevState.findIndex(
            n => n.tag === String(data.ticketId)
          );
          if (notfiticationIndex !== -1) {
            prevState[ notfiticationIndex ].close();
            prevState.splice(notfiticationIndex, 1);
            return [ ...prevState ];
          }
          return prevState;
        });
      }
    });

    socket.on("appMessage", data => {
      let hasPermission = data.ticket.queueId === null;
        for (let q of user.queues) {
      if (q.id === data.ticket.queueId) {
        hasPermission = true;
        }
      }
      if (
      // data.action === "create" &&
      !data.message.read && hasPermission &&
      // (data.ticket.userId === user?.id || !data.ticket.userId)
      (data.ticket.userId === user?.id)
      ) {
        setNotifications(prevState => {
          const ticketIndex = prevState.findIndex(t => t.id === data.ticket.id);
          if (ticketIndex !== -1) {
            prevState[ticketIndex] = data.ticket;
            return [...prevState];
          }
          return [data.ticket, ...prevState];
        });

        const shouldNotNotificate =
          (data.message.ticketId === ticketIdRef.current &&
            document.visibilityState === "visible") ||
          (data.ticket.userId && data.ticket.userId !== user?.id) ||
          data.ticket.isGroup;

        if (shouldNotNotificate) return;

        handleNotifications(data);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ user, profile, queues ]);

  const handleNotifications = data => {

    const { message, contact, ticket } = data;

    if (message?.fromMe) return;

    let formattedDate = "";

    try {
      formattedDate = format(new Date(), "HH:mm");
    } catch (error) {
      console.error("Error formatting date: ", error);
    }

    const options = {
      body: `${ message.body } - ${ formattedDate }`,
      icon: contact.profilePicUrl,
      tag: ticket.id,
      renotify: true,
    };

    const notification = new Notification(
      `${ i18n.t("tickets.notification.message") } ${ contact.name }`,
      options
    );

    notification.onclick = e => {
      e.preventDefault();
      window.focus();
      historyRef.current.push(`/chats/${ticketId}`);
    };

    setDesktopNotifications(prevState => {
      const notfiticationIndex = prevState.findIndex(
        n => n.tag === notification.tag
      );
      if (notfiticationIndex !== -1) {
        prevState[ notfiticationIndex ] = notification;
        return [ ...prevState ];
      }
      return [ notification, ...prevState ];
    });

    soundAlertRef.current();
  };

  const handleClick = () => {
    setIsOpen(prevState => !prevState);
  };

  const handleClickAway = () => {
    setIsOpen(false);
  };

  const NotificationTicket = ({ children }) => {
    return <div id="divticket" onClick={ handleClickAway }>{ children }</div>;
  };


  function renderNotification(total) {
    if (total > 0) {

    }
  }

  // Setar notificacçoes para o usuario vizualizar
  if (document.title.includes(')')) {
    var removerNotificacao = document.title.split(")");
    document.title = removerNotificacao[ 1 ]
  }
  var titulo = ""
  titulo = document.title
  if (notifications.length > 0) {
    document.title = `(${ notifications.length }) ${ titulo }`;
  }

  return (
    <>
      <IconButton
        onClick={ handleClick }
        ref={ anchorEl }
        aria-label="Open Notifications"
        color="inherit"
      >
        <Badge badgeContent={ notifications.length } max={ 99 } color="secondary" >
          { renderNotification(notifications.length) }
          <Iconify icon="mdi:message-badge-outline" style={ { margin: '3px 2px 0px 2px' } } width={ 20 } height={ 20 } sx={ { mr: 1, color: 'text.primary' } } />
        </Badge>
      </IconButton>
      <div>
        <MenuPopover
          disableScrollLock
          open={ isOpen }
          anchorEl={ anchorEl.current }
          sx={ {
            mt: 1.5,
            ml: 0.75,
            width: 320,
            '& .MuiMenuItem-root': {
              px: 1.5,
              height: 65,
              borderRadius: 0.75,
            },
          } }
          onClose={ handleClickAway }

        >
          <Typography variant="h6" sx={ { p: 1.5 } }>
            { i18n.t("userType.translate.notifications") } <Typography component="span">({ notifications.length })</Typography>
          </Typography>
          <Scrollbar sx={ { height: 65 * 6 } }>
            { notifications.length === 0 ? (
              <ListItem>
                <ListItemText>{ i18n.t("notifications.noTickets") }</ListItemText>
              </ListItem>
            ) : (
            notifications.map(ticket => (
              <NotificationTicket key={ticket.id}>
                <TicketListItem ticket={ticket} />
              </NotificationTicket>
            ))
            ) }
          </Scrollbar>
        </MenuPopover>
      </div>

    </>
  );
};

export default NotificationsPopOver;
