import React from "react";
import { useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { makeStyles } from 'tss-react/mui';
import useSettings from '../../hooks/useSettings';
import TicketsManager from "../../components/TicketsManager";
import Ticket from "../../components/Ticket";
import Page from '../../components/Page';
import { i18n } from "../../translate/i18n";
import Hidden from "@mui/material/Hidden";
import { Container, Grid, Stack } from '@mui/material';

const useStyles = makeStyles()((theme) => {
  return {
    contactsWrapper: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      overflowY: "hidden",
    },
    contactsWrapperSmall: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      overflowY: "hidden",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    messagessWrapper: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
    },
    welcomeMsg: {
      display: "flex",
      width: '100%',
      justifyContent: "space-evenly",
      alignItems: "center",
      height: 'calc(100vh - 80px)',
      textAlign: "center",
      borderRadius: 0,
    },
    ticketsManager: {},
    ticketsManagerClosed: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  }
});

const Chat = () => {
  const { classes } = useStyles();
  const { ticketId } = useParams();
  const { themeStretch } = useSettings();
  return (
    <div style={{ marginTop: '10px' }}>
      <Page title={i18n.t("mainDrawer.listItems.tickets")}>
        <Container spacing={3} maxWidth={false} className={classes.mobileLayout}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              style={{}}
              className={
                ticketId ? classes.contactsWrapperSmall : classes.contactsWrapper
              }
            >
              <TicketsManager />
            </Grid>
            <Grid item xs={12} md={8} className={classes.messagessWrapper}>
              {ticketId ? (
                <>
                  <Ticket />
                </>
              ) : (
                <Hidden only={["sm", "xs"]}>
                  <Paper className={classes.welcomeMsg}>
                    <span>{i18n.t("chat.noTicketMessage")}</span>
                  </Paper>
                </Hidden>
              )}
            </Grid>
          </Grid>
        </Container>
      </Page>
    </div>
  );
};

export default Chat;
