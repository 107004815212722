import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "socket.io-client";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import SearchIcon from "@mui/icons-material/Search";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ContactModal from "../../components/ContactModal";
import ConfirmationModal from "../../components/ConfirmationModal/";
import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import NewTicketModal from "../../components/NewTicketModal/index.js";
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Avatar,
  InputAdornment,
  Paper,
  IconButton,
  Button,
  TextField,
  Checkbox,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Badge
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// _mock_
import { _contacts } from '../../_mock';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

// sections
import { contactsHead, contactsToolbar, UserMoreMenu } from '../../sections/@dashboard/user/list';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'number', label: 'WhatsApp', alignRight: false },
  { id: 'role', label: 'Email', alignRight: false },
  { id: 'Actions', label: 'Actions', alignRight: false },
];

// ----------------------------------------------------------------------

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[ contactIndex ] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [ ...state, ...newContacts ];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[ contactIndex ] = contact;
      return [ ...state ];
    } else {
      return [ contact, ...state ];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [ ...state ];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles()((theme) => {
  return {
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
  }
});

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${ theme.palette.background.paper }`,
}));

const Contacts = () => {
  const history = useHistory();
  const { ticketId } = useParams();
  const { user } = useContext(AuthContext);
  const [ loading, setLoading ] = useState(false);
  const [ searchParam, setSearchParam ] = useState("");
  const [ selectedContactId, setSelectedContactId ] = useState(null);
  const [ contactModalOpen, setContactModalOpen ] = useState(false);
  const [ newTicketModalOpen, setNewTicketModalOpen ] = useState(false);
  const [ contactTicket, setContactTicket ] = useState({})
  const [ deletingContact, setDeletingContact ] = useState(null);
  const [ confirmOpen, setConfirmOpen ] = useState(false);
  const [ hasMore, setHasMore ] = useState(false);
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const [ page, setPage ] = useState(0);
  const [ order, setOrder ] = useState('asc');
  const [ selected, setSelected ] = useState([]);
  const [ orderBy, setOrderBy ] = useState('name');
  const [ filterName, setFilterName ] = useState('');
  const [ rowsPerPage, setRowsPerPage ] = useState(5);


  const [ contacts, dispatch ] = useReducer(reducer, []);

  // ----------------------------------------------------------------------
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - contacts.length) : 0;

  const filteredUsers = applySortFilter(contacts, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && Boolean(filterName);
  // ----------------------------------------------------------------------

  useEffect(() => {
    dispatch({ type: "RESET" });
  }, [ searchParam ]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/", {
            params: { searchParam },
          });
          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [ searchParam ]);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("contact", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };


  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined) {
      history.push(`/chats/${ticketId}`);
    }
  }

  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${ contactId }`);
      toast.success(i18n.t("contacts.toasts.deleted"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
  };

  const handleimportContact = async () => {
    try {
      await api.post("/contacts/import");
      history.go(0);
    } catch (err) {
      toastError(err);
    }
  };

  const handleexportContact = async () => {
    // console.log("Export button clicked");
    try {
      const response = await api.get("/contacts/export");
      // console.log("Export successful");
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "contacts.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      toast.success(i18n.t("contacts.exportSuccess"));
    } catch (err) {
      // console.log("Export failed", err);
      toast.error(i18n.t("contacts.exportError"));
    }
  };

  const firstWordInCapsLock = text => {
    if (!text) return "";
    return text[ 0 ].toUpperCase() + text.substring(1)
  };

  return (
    <Can
      role={user.profile}
      perform="admin-page:view"
      yes={() => (
    <>
      <div style={ { marginTop: '65px' } }>
        <NewTicketModal
          modalOpen={ newTicketModalOpen }
          initialContact={ contactTicket }
          onClose={ (ticket) => {
            handleCloseOrOpenTicket(ticket);
          } }
        />
        <ContactModal
          open={ contactModalOpen }
          onClose={ handleCloseContactModal }
          aria-labelledby="form-dialog-title"
          contactId={ selectedContactId }
        ></ContactModal>
        <ConfirmationModal
          title={
            deletingContact
              ? `${ i18n.t("contacts.confirmationModal.deleteTitle") } ${ deletingContact.name
              }?`
              : `${ i18n.t("contacts.confirmationModal.exportTitlte") }`
          }
          open={ confirmOpen }
          onClose={ setConfirmOpen }
          onConfirm={ (e) =>
            deletingContact
              ? handleDeleteContact(deletingContact.id)
              : handleexportContact()
          }
        >
          { deletingContact
            ? `${ i18n.t("contacts.confirmationModal.deleteMessage") }`
            : `${ i18n.t("contacts.confirmationModal.exportMessage") }` }
        </ConfirmationModal>
        <Page title={ i18n.t("mainDrawer.listItems.contacts") }>
          <Container maxWidth={ themeStretch ? false : 'lg' }>
            <HeaderBreadcrumbs
              heading={ i18n.t("mainDrawer.listItems.contacts") }
              action={
                <>
                  <Can
                    role={ user.profile }
                    perform="contacts-page:exportContact"
                    yes={ () => (
                      <Button
                        variant="contained"
                        startIcon={ <Iconify icon={ 'eva:plus-fill' } /> }
                        style={ { marginLeft: "5px", marginRight: "5px" } }
                        onClick={ (e) => setConfirmOpen(true) }
                      >
                        { i18n.t("contacts.buttons.export") }
                      </Button>
                    ) }
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={ <Iconify icon={ 'eva:plus-fill' } /> }
                    onClick={ handleOpenContactModal }
                  >
                    { i18n.t("contacts.buttons.add") }
                  </Button>
                </>
              }
            />

            <Card>
              <TextField
                style={ { padding: '20px' } }
                type="search"
                placeholder={ i18n.t("userType.translate.searchContacts") }
                value={ searchParam }
                onChange={ handleSearch }
                InputProps={ {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={ { color: "gray" } } />
                    </InputAdornment>
                  ),
                } }
              />
              <Scrollbar>
                <TableContainer sx={ { minWidth: 800 } }>
                  <Table>
                    <TableHead>
                      <TableCell align="left">{ i18n.t("connections.table.name") }</TableCell>
                      <TableCell align="left">{ i18n.t("userType.translate.number") }</TableCell>
                      <TableCell align="left">{ i18n.t("userType.translate.email") }</TableCell>
                      <TableCell align="left">{ i18n.t("userType.translate.quotation") }</TableCell>
                      <TableCell align="center">{ i18n.t("userType.translate.action") }</TableCell>
                    </TableHead>
                    <contactsHead
                      headLabel={ TABLE_HEAD }
                      numSelected={ contacts.length }
                      onRequestSort={ handleRequestSort }
                    />
                    <TableBody>
                      { contacts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { id, name, number, email, quotation, profilePicUrl } = row;
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <TableRow
                            hover
                            key={ id }
                            tabIndex={ -1 }
                            role="checkbox"
                            selected={ isItemSelected }
                            aria-checked={ isItemSelected }
                          >
                            <TableCell sx={ { display: 'flex', alignItems: 'center' } }>
                              <Badge
                                overlap="circular"
                                anchorOrigin={ { vertical: 'bottom', horizontal: 'right' } }
                                // badgeContent={ <Tooltip title={ telegramId ? firstWordInCapsLock('telegram') : firstWordInCapsLock('whatsapp') } placement="right">{ telegramId ? renderChannel('telegram') : renderChannel('whatsapp') }</Tooltip> }
                              >
                                <Avatar alt={ name } src={ profilePicUrl } sx={ { marginRight: '5px' } } />
                              </Badge>

                              <Typography variant="subtitle2" noWrap>
                                { name }
                              </Typography>
                            </TableCell>
                            <TableCell align="left">{ number }</TableCell>
                            <TableCell align="left">{ email }</TableCell>
                            <TableCell align="left">{ quotation }</TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={ () => hadleEditContact(id) }
                              >
                                <EditIcon />
                              </IconButton>
                              <Can
                                role={ user.profile }
                                perform="contacts-page:deleteContact"
                                yes={ () => (
                                  <IconButton
                                    size="small"
                                    onClick={ (e) => {
                                      setConfirmOpen(true);
                                      setDeletingContact(row);
                                    } }
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                ) }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      }) }
                      { emptyRows > 0 && (
                        <TableRow style={ { height: 53 * emptyRows } }>
                          <TableCell colSpan={ 6 } />
                        </TableRow>
                      ) }
                    </TableBody>
                    { isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={ 6 } sx={ { py: 3 } }>
                            <SearchNotFound searchQuery={ filterName } />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) }
                    { loading && <TableRowSkeleton avatar columns={ 2 } /> }
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination style={ { marginTop: 15 } }
                labelDisplayedRows={ ({ from, to, count }) => `${ i18n.t("userType.translate.result") }: ${ count } - ${ i18n.t("userType.translate.page") } ${ from } ${ i18n.t("userType.translate.of") } ${ to }` }
                labelRowsPerPage={ i18n.t("userType.translate.resultsPerPage") }
                rowsPerPageOptions={ [ 5, 20, 40, 60, 80, 100 ] }
                component="div"
                count={ contacts.length }
                rowsPerPage={ rowsPerPage }
                page={ page }
                onPageChange={ (e, page) => setPage(page) }
                onRowsPerPageChange={ handleChangeRowsPerPage }
              />
            </Card>
          </Container>
        </Page>
      </div>

    </>
      )}
      no={() => (
        <Redirect to="/chats" />
      )}
    />
  );
};


export default Contacts;


function descendingComparator(a, b, orderBy) {
  if (b[ orderBy ] < a[ orderBy ]) {
    return -1;
  }
  if (b[ orderBy ] > a[ orderBy ]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [ el, index ]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[ 0 ], b[ 0 ]);
    if (order !== 0) return order;
    return a[ 1 ] - b[ 1 ];
  });
  if (query) {
    return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[ 0 ]);
}

