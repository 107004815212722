import React, { useContext } from "react";
import { Route as RouterRoute, Redirect } from "react-router-dom";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";

const Route = ({ component: Component, isPrivate = false, path, ...rest }) => {
  const { isAuth, loading, user } = useContext(AuthContext);

  // If loading, show loading spinner
  if (loading) return <BackdropLoading />;

  // If the route is private and the user is not authenticated or their profile data hasn't loaded yet, redirect to login
  if (isPrivate && !isAuth) {
    return <Redirect to={{ pathname: "/login", state: { from: rest.location } }} />;
  }

  // If the user is authenticated and trying to access the login page, redirect them to home
  if (isAuth && path === "/login") {
    return <Redirect to={{ pathname: "/chats", state: { from: rest.location } }} />;
  }

  // Otherwise, just render the component
  return <RouterRoute path={path} {...rest} component={Component} />;
};

export default Route;
