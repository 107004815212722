import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { TicketContext } from '../TicketContext';

import { toast } from "react-toastify";
import openSocket from "socket.io-client";
import clsx from "clsx";

import { makeStyles } from 'tss-react/mui';
import { Paper, Button, Typography, Tooltip, IconButton, Box } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { TagsContainer } from "../TagsContainer";
import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInput/";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtons";
import MessagesList from "../MessagesList";
import api from "../../services/api";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

const drawerWidth = 260;

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      '@media (max-width: 600px)': {
        borderRadius: '5px',
      },
      display: "flex",
      height: "100%",
      position: "relative",
      overflow: "hidden",
    },

    ticketInfo: {
      maxWidth: "100%",
      flexBasis: "100%",
      display: "flex",
      justifyContent: 'space-evenly',
      alignItems: 'center',
      alignContent: 'center',
      '@media (max-width: 600px)': {
        maxWidth: "80%",
        flexBasis: "80%",
      },
    },
    ticketActionButtons: {
      maxWidth: "100%",
      flexBasis: "100%",
      display: "flex",
      justifyContent: 'space-evenly',
      alignItems: 'center',
      alignContent: 'center',
      '@media (max-width: 600px)': {
        maxWidth: "100%",
        flexBasis: "100%",
        marginBottom: "5px",
      },
    },

    mainWrapper: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",

      borderLeft: "0",
      marginRight: -drawerWidth,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },

    mainWrapperShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },

    ticketText: {
      color: "green",
      fontSize: "0.8rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.7rem",
      },
    },
    ticketItem: {
      display: "inline",
      margin: theme.spacing(0, 1),
    },
    showMoreButton: {
      color: theme.palette.common.white,
      fontSize: '0.8rem',
      textDecoration: 'underline',
      padding: 0,
      textTransform: 'none',
    },
    showLessButton: {
      color: theme.palette.common.white,
      fontSize: '0.8rem',
      textDecoration: 'underline',
      padding: 0,
      textTransform: 'none',
    },
    headerContent: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },

    otherTicketsText: {
      // backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      flexBasis: "40%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        flexBasis: "40%",
      },
    },

  }
});

const Ticket = ({ onClose, onBack, fromEmbedded}) => {
  const { ticketId: ticketIdFromParams } = useParams();
  const ticketIdFromContext = useContext(TicketContext);
  const ticketId = ticketIdFromParams || ticketIdFromContext;
  const history = useHistory();
  const { classes } = useStyles();

  const { user } = useContext(AuthContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});
  const [existingTickets, setExistingTickets] = useState([]);
// eslint-disable-next-line no-unused-vars
  const [currentTicketId, setCurrentTicketId] = useState(null);
  const [loadingExistingTickets, setLoadingExistingTickets] = useState(true);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const [ droppedFiles, setDroppedFiles ] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const dateFormatter = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    return dateFormatter.format(date);
  };


  const toggleShowAllTickets = () => {
    setShowAllTickets((prevShowAllTickets) => !prevShowAllTickets);
  };

  const displayedTickets = showAllTickets
    ? existingTickets
    : existingTickets.slice(0, 2);



  async function fetchExistingTickets(contactNumber) {
    const response = await api.get(`/chats/check/${contactNumber}`);
    const existingTickets = response.data;
    return existingTickets;
  }

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const { data } = await api.get(`/chats/${ticketId}`);
          const { queueId } = data;
          const { queues, profile } = user;

          if (queueId) { // If the ticket has a queueId
            const queueAllowed = queues.find(q => q.id === queueId);
            if (queueAllowed === undefined && profile !== 'admin') {
              toast.error('Access not allowed', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              history.push("/chats");
              return;
            }
          }

          // If the ticket does not have a queueId, the user can access it
          setContact(data.contact);
          setTicket(data);
          setLoading(false);
          setCurrentTicketId(ticketId);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchTicket();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [ticketId, user, history]);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on("ticket", (data) => {
      if (data.action === "update") {
        setTicket(data.ticket);
      }

      if (data.action === "delete") {
        toast.success("Chat deleted sucessfully.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        history.push("/chats");
      }
    });

    socket.on("contact", (data) => {
      if (data.action === "update") {
        setContact((prevState) => {
          if (prevState.id === data.contact?.id) {
            return { ...prevState, ...data.contact };
          }
          return prevState;
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, ticket, history]);


  useEffect(() => {
    // console.log(contact);
    async function loadExistingTickets() {
      if (contact) {
        setLoadingExistingTickets(true);
        const tickets = await fetchExistingTickets(contact.number);
        const filteredTickets = tickets.filter(
          (existingTicket) => existingTicket.id.toString() !== ticketId
        );
        setExistingTickets(filteredTickets);
        setLoadingExistingTickets(false);
      }
    }

    loadExistingTickets();
  }, [contact, ticketId]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const renderMessagesList = () => {
    return (
      <>
        <MessagesList
          ticket={ticket}
          ticketId={ticket.id}
          isGroup={ticket.isGroup}
          droppedFiles={droppedFiles}
          setDroppedFiles={setDroppedFiles}
        />
        <MessageInput id="inputMensagens" ticketId={ticket.id} ticketStatus={ticket.status} style={{ marginLeft: '50px' }} droppedFiles={droppedFiles} />
      </>
    )
  }

  return (
    <div className={classes.root} id="drawer-container" style={{ height: 'calc(100vh - 80px)', width: '100%' }}>
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerOpen,
        })}
      >
        <TicketHeader loading={loading} onBack={onBack}>
          <div className={classes.ticketInfo}>
            <TicketInfo
              contact={contact}
              ticket={ticket}
              onClick={handleDrawerOpen}
            />
<div className={classes.headerContent}>
    {!loadingExistingTickets && existingTickets.length > 0 && (
        <Box
            className={classes.otherTicketsText}
            sx={{
                overflowY: 'auto',
                maxHeight: '150px',  // Adjust to the maximum height you find suitable
            }}
        >
            {displayedTickets.map((existingTicket) => (
                <Tooltip 
                    key={existingTicket.id} 
                    title={`ID: ${existingTicket.id} User: ${existingTicket.user ? existingTicket.user.name : "No user"}, Date: ${existingTicket.lastMessageAt ? formatDate(existingTicket.lastMessageAt) : "No message date"}`}
                >
                    {/* <Typography
                        variant="body2"
                        className={`${classes.ticketText} ${classes.ticketItem}`}
                    >
                        ID: {existingTicket.id} */}
                        <IconButton size="small">
                            <InfoIcon fontSize="inherit" />
                        </IconButton>
                    {/* </Typography> */}
                </Tooltip>
            ))}
            {!showAllTickets && existingTickets.length > 2 && (
                <Button
                    className={classes.showLessButton}
                    onClick={toggleShowAllTickets}
                >
                    Show More
                </Button>
            )}
            {showAllTickets && (
                <Button
                    className={classes.showLessButton}
                    onClick={() => setShowAllTickets(false)}
                >
                    Show Less
                </Button>
            )}
        </Box>
    )}
</div>
            <TicketActionButtons ticket={ticket} fromEmbedded={fromEmbedded}/>
          </div>
        </TicketHeader>
        <Paper>
          <TagsContainer ticket={ticket} />
        </Paper>
        <ReplyMessageProvider>
          {renderMessagesList()}
        </ReplyMessageProvider>
      </Paper>
      <ContactDrawer
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        contact={contact}
        loading={loading}
      />
    </div>
  );
};

export default Ticket;
