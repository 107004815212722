import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from 'tss-react/mui';
import { green } from "@mui/material/colors";

import {
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	DialogActions,
	CircularProgress,
	TextField,
	Checkbox,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Switch,
	FormControlLabel,
} from "@mui/material";

import dayjs, { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";

import { AuthContext } from "../../context/Auth/AuthContext";

import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const useStyles = makeStyles()((theme) => {
	return {
		root: {
			display: "flex",
			flexWrap: "wrap",
		},

		multFieldLine: {
			display: "flex",
			"& > *:not(:last-child)": {
				marginRight: theme.spacing(1),
			},
		},

		btnWrapper: {
			position: "relative",
		},

		buttonProgress: {
			color: green[500],
			position: "absolute",
			top: "50%",
			left: "50%",
			marginTop: -12,
			marginLeft: -12,
		},
	}
});

const SessionSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
	const { user: loggedInUser } = useContext(AuthContext);

	const { classes } = useStyles();
	const initialState = {
		name: "",
		greetingMessage: "",
		isDefault: false,
		sendonly: false,
		autoCloseMessage: "",
		token: "",
	};

	function msToTime(duration) {
		var milliseconds = Math.floor((duration % 1000) / 100),
			seconds = Math.floor((duration / 1000) % 60),
			minutes = Math.floor((duration / (1000 * 60)) % 60),
			hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
		hours = (hours < 10) ? "0" + hours : hours;
		minutes = (minutes < 10) ? "0" + minutes : minutes;
		seconds = (seconds < 10) ? "0" + seconds : seconds;
		return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
	}

	const [autoClose, setAutoClose] = useState(false);
	const [whatsApp, setWhatsApp] = useState(initialState);
	// const [channel, setChannel] = useState('whatsapp');
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [sendonly, setSendonly] = useState(false); // Create a state for sendonly


	useEffect(() => {
		const fetchSession = async () => {

			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`whatsapp/${whatsAppId}`);
				setWhatsApp(data);
				// setChannel(data.channel);
				// setAutoClose(data.autoClose)
				// if (data?.autoCloseTime) {
				// 	let date = new Date().toISOString().split('T')[0]
				// 	let time = msToTime(Number(data.autoCloseTime));
				// 	setTimeToAutoClose(new Date(`${date}T${time}`))
				// }
				setSendonly(data.sendonly);
				const whatsQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(whatsQueueIds);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, [whatsAppId]);

	const handleSaveWhatsApp = async values => {
		try {

	      const submission = {
	        ...values,
	        queueIds: selectedQueueIds,
	        sendonly, // Include sendonly in the submission
	      };

			if (whatsAppId) {
				await api.put(`/whatsapp/${whatsAppId}`, submission);
			} else {
				await api.post("/whatsapp", submission);
			}
			toast.success(i18n.t("whatsappModal.success"), {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};



	const handleClose = () => {
		onClose();
		setWhatsApp(initialState);
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="sm"
				fullWidth
				scroll="paper"
			>
				<DialogTitle>
					{whatsAppId
						? i18n.t("whatsappModal.title.edit")
						: i18n.t("whatsappModal.title.add")}
				</DialogTitle>
				<Formik
					initialValues={whatsApp}
					enableReinitialize={true}
					validationSchema={SessionSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							let object = {
								"name": values.name,
								"greetingMessage": values.greetingMessage,
								"isDefault": values.isDefault,
								"queueIds": selectedQueueIds
							}
							handleSaveWhatsApp(object);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.name")}
										autoFocus
										name="name"
										fullWidth
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>
									<FormControlLabel
										// style={{ display: 'none' }}
										control={
											<Field
												as={Checkbox}
												color="primary"
												name="isDefault"
												checked={values.isDefault}
											/>
										}
										label={i18n.t("whatsappModal.form.default")}
									/>
						            <FormControlLabel
						              control={
						                <Field
						                  as={Checkbox}
						                  color="primary"
						                  name="sendonly"
						                  checked={sendonly}
						                  onChange={(event) => setSendonly(event.target.checked)}
						                />
						              }
						              label="Send Only"
						            />
								</div>
								{/* <div>
									<Field
										as={TextField}
										label={i18n.t("queueModal.form.greetingMessage")}
										type="greetingMessage"
										multiline
										rows={5}
										fullWidth
										name="greetingMessage"
										error={
											touched.greetingMessage && Boolean(errors.greetingMessage)
										}
										helperText={
											touched.greetingMessage && errors.greetingMessage
										}
										variant="outlined"
										margin="dense"
									/>
								</div> */}

								<QueueSelect
									selectedQueueIds={selectedQueueIds}
									onChange={selectedIds => setSelectedQueueIds(selectedIds)}
								/>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("whatsappModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{whatsAppId
										? i18n.t("whatsappModal.buttons.okEdit")
										: i18n.t("whatsappModal.buttons.okAdd")}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default WhatsAppModal;