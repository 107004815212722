import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "socket.io-client";
import { makeStyles } from 'tss-react/mui';
import {
  Card,
  Table,
  Avatar,
  InputAdornment,
  Paper,
  IconButton,
  Button,
  TextField,
  Checkbox,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';

import { Edit, DeleteOutline } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import QuickAnswersModal from "../../components/QuickAnswersModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useTheme } from '@mui/material/styles';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// _mock_
import { _contacts } from '../../_mock';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import { contactsHead, contactsToolbar, UserMoreMenu } from '../../sections/@dashboard/user/list';
const reducer = (state, action) => {
  if (action.type === "LOAD_QUICK_ANSWERS") {
    const quickAnswers = action.payload;
    const newQuickAnswers = [];

    quickAnswers.forEach((quickAnswer) => {
      const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswer.id);
      if (quickAnswerIndex !== -1) {
        state[ quickAnswerIndex ] = quickAnswer;
      } else {
        newQuickAnswers.push(quickAnswer);
      }
    });

    return [ ...state, ...newQuickAnswers ];
  }

  if (action.type === "UPDATE_QUICK_ANSWERS") {
    const quickAnswer = action.payload;
    const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswer.id);

    if (quickAnswerIndex !== -1) {
      state[ quickAnswerIndex ] = quickAnswer;
      return [ ...state ];
    } else {
      return [ quickAnswer, ...state ];
    }
  }

  if (action.type === "DELETE_QUICK_ANSWERS") {
    const quickAnswerId = action.payload;

    const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswerId);
    if (quickAnswerIndex !== -1) {
      state.splice(quickAnswerIndex, 1);
    }
    return [ ...state ];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles()((theme) => {
  return {
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
  }
});

const QuickAnswers = () => {
  const { classes } = useStyles();

  const [ loading, setLoading ] = useState(false);
  const [ pageNumber, setPageNumber ] = useState(1);
  const [ searchParam, setSearchParam ] = useState("");
  const [ quickAnswers, dispatch ] = useReducer(reducer, []);
  const [ selectedQuickAnswers, setSelectedQuickAnswers ] = useState(null);
  const [ quickAnswersModalOpen, setQuickAnswersModalOpen ] = useState(false);
  const [ deletingQuickAnswers, setDeletingQuickAnswers ] = useState(null);
  const [ confirmModalOpen, setConfirmModalOpen ] = useState(false);
  const [ hasMore, setHasMore ] = useState(false);
  const [ selectedContactId, setSelectedContactId ] = useState(null);
  const [ contactModalOpen, setContactModalOpen ] = useState(false);
  const [ newTicketModalOpen, setNewTicketModalOpen ] = useState(false);
  const [ contactTicket, setContactTicket ] = useState({})
  const [ deletingContact, setDeletingContact ] = useState(null);
  const [ confirmOpen, setConfirmOpen ] = useState(false);
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const [ page, setPage ] = useState(0);
  const [ order, setOrder ] = useState('asc');
  const [ selected, setSelected ] = useState([]);
  const [ orderBy, setOrderBy ] = useState('name');
  const [ filterName, setFilterName ] = useState('');
  const [ rowsPerPage, setRowsPerPage ] = useState(5);

  const { user } = useContext(AuthContext);


  // ----------------------------------------------------------------------
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - quickAnswers.length) : 0;

  const filteredUsers = applySortFilter(quickAnswers, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && Boolean(filterName);
  // ----------------------------------------------------------------------



  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [ searchParam ]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchQuickAnswers = async () => {
        try {
          const { data } = await api.get("/quickAnswers/", {
            params: { searchParam, pageNumber },
          });
          var FiltroRespostas = []
          for (let i = 0; i < data.quickAnswers.length; i++) {
              FiltroRespostas.push(data.quickAnswers[ i ])
          }
          dispatch({ type: "LOAD_QUICK_ANSWERS", payload: FiltroRespostas });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchQuickAnswers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [ searchParam, pageNumber ]);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("quickAnswer", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_QUICK_ANSWERS", payload: data.quickAnswer });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_QUICK_ANSWERS",
          payload: +data.quickAnswerId,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenQuickAnswersModal = () => {
    setSelectedQuickAnswers(null);
    setQuickAnswersModalOpen(true);
  };

  const handleCloseQuickAnswersModal = () => {
    setSelectedQuickAnswers(null);
    setQuickAnswersModalOpen(false);
  };

  const handleEditQuickAnswers = (quickAnswer) => {
    setSelectedQuickAnswers(quickAnswer);
    setQuickAnswersModalOpen(true);
  };

  const handleDeleteQuickAnswers = async (quickAnswerId) => {
    try {
      await api.delete(`/quickAnswers/${ quickAnswerId }`);
      toast.success(i18n.t("quickAnswers.toasts.deleted"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (err) {
      toastError(err);
    }
    setDeletingQuickAnswers(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <>
      <div style={ { marginTop: '65px' } }>
        <ConfirmationModal
          title={
            deletingQuickAnswers &&
            `${ i18n.t("quickAnswers.confirmationModal.deleteTitle") } ${ deletingQuickAnswers.shortcut
            }?`
          }
          open={ confirmModalOpen }
          onClose={ setConfirmModalOpen }
          onConfirm={ () => handleDeleteQuickAnswers(deletingQuickAnswers.id) }
        >
          { i18n.t("quickAnswers.confirmationModal.deleteMessage") }
        </ConfirmationModal>
        <QuickAnswersModal
          open={ quickAnswersModalOpen }
          onClose={ handleCloseQuickAnswersModal }
          aria-labelledby="form-dialog-title"
          quickAnswerId={ selectedQuickAnswers && selectedQuickAnswers.id }
        ></QuickAnswersModal>
        <Page title={ i18n.t("mainDrawer.listItems.quickAnswers") }>
          <Container maxWidth={ themeStretch ? false : 'lg' }>
            <HeaderBreadcrumbs
              heading={ i18n.t("mainDrawer.listItems.quickAnswers") }
              action={
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={ <Iconify icon={ 'eva:plus-fill' } /> }
                    onClick={ handleOpenQuickAnswersModal }
                  >
                    { i18n.t("quickAnswers.buttons.add") }
                  </Button>
                </>
              }
            />
            <Card>
              <TextField
                style={ { padding: '20px' } }
                placeholder={ i18n.t("quickAnswers.searchPlaceholder") }
                type="search"
                value={ searchParam }
                onChange={ handleSearch }
                InputProps={ {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={ { color: "gray" } } />
                    </InputAdornment>
                  ),
                } }
              />
              <Scrollbar>
                <TableContainer sx={ { minWidth: 800 } }>
                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          { i18n.t("quickAnswers.table.shortcut") }
                        </TableCell>
                        <TableCell align="center">
                          { i18n.t("quickAnswers.table.message") }
                        </TableCell>
                        <TableCell align="center">
                          { i18n.t("quickAnswers.table.actions") }
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { quickAnswers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { id, shortcut, message, email, profilePicUrl } = row;
                        const isItemSelected = selected.indexOf(shortcut) !== -1;
                        return (
                          <TableRow
                            hover
                            key={ id }
                            tabIndex={ -1 }
                            role="checkbox"
                            selected={ isItemSelected }
                            aria-checked={ isItemSelected }
                          >
                            <TableCell align="center">{ shortcut }</TableCell>
                            <TableCell align="center">{ message }</TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={ () => handleEditQuickAnswers(row) }
                              >
                                <Edit />
                              </IconButton>

                              <IconButton
                                size="small"
                                onClick={ (e) => {
                                  setConfirmModalOpen(true);
                                  setDeletingQuickAnswers(row);
                                } }
                              >
                                <DeleteOutline />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      }) }
                      { emptyRows > 0 && (
                        <TableRow style={ { height: 53 * emptyRows } }>
                          <TableCell colSpan={ 6 } />
                        </TableRow>
                      ) }
                    </TableBody>
                    { isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={ 6 } sx={ { py: 3 } }>
                            <SearchNotFound searchQuery={ filterName } />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) }
                    { loading && <TableRowSkeleton columns={ 3 } /> }
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination style={ { marginTop: 15 } }
                labelDisplayedRows={ ({ from, to, count }) => `${ i18n.t("userType.translate.result") } ${ count } - ${ i18n.t("userType.translate.page") } ${ from } ${ i18n.t("userType.translate.of") } ${ to }` }
                labelRowsPerPage={ i18n.t("userType.translate.resultsPerPage") }
                rowsPerPageOptions={ [ 5, 20, 40, 60, 80, 100 ] }
                component="div"
                count={ quickAnswers.length }
                rowsPerPage={ rowsPerPage }
                page={ page }
                onPageChange={ (e, page) => setPage(page) }
                onRowsPerPageChange={ handleChangeRowsPerPage }
              />
            </Card>
          </Container>
        </Page>
      </div>

    </>
  );
};

export default QuickAnswers;

function descendingComparator(a, b, orderBy) {
  if (b[ orderBy ] < a[ orderBy ]) {
    return -1;
  }
  if (b[ orderBy ] > a[ orderBy ]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [ el, index ]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[ 0 ], b[ 0 ]);
    if (order !== 0) return order;
    return a[ 1 ] - b[ 1 ];
  });
  if (query) {
    return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[ 0 ]);
}

