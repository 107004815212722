import React, { useState, useRef, useEffect, useContext } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
// @mui
import { AuthContext } from "../../context/Auth/AuthContext";
import { Container, Stack } from '@mui/material';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getBoard, persistCard, updateStage, updateTicket, updateMessage, increaseUnreadMessages, clearUnreadMessages, updateTags, removeClosedTicket, removeUnUpdateableTicket } from '../../redux/slices/kanban';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { SkeletonKanbanColumn } from '../../components/skeleton';
// sections
import { KanbanColumn } from '../../sections/@dashboard/kanban';
import { toast } from 'react-toastify';
import openSocket from "socket.io-client";


let pageNumber = 1; // Define a variable for the current page number

export default function Kanban() {
  const boardRef = useRef();
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const { board } = useSelector((state) => state.kanban);

  const showAll = user?.profile === 'admin';
  const selectedQueueIds = user?.queues?.map(queue => queue.id) || [];

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    const shouldUpdateTicket = ticket =>
      (!ticket.userId || ticket.userId === user?.id || showAll) &&
      (!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1);

    const notBelongsToUserQueues = ticket =>
      ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

    socket.on('connect', () => {
      socket.emit("joinTickets", 'pending');
      socket.emit("joinTickets", 'open');
    });

    socket.on('ticket', data => {
      if (data.action === "update") {
        const isTicketUpdateable = shouldUpdateTicket(data.ticket);

        if (isTicketUpdateable) {
          dispatch(updateTicket(data.ticket));
        } else {
          dispatch(removeUnUpdateableTicket(data.ticket.id));  // dispatch action to remove ticket
        }
      };
      if (data.action === 'updateUnread' && data.ticketId) {
        dispatch(clearUnreadMessages(data.ticketId));
      }
      if (data.action === 'updateTags' && shouldUpdateTicket(data.ticket)) {
        dispatch(updateTags(data.ticket));
      }
    });

    socket.on('ticketClosed', ticket => {
      if (shouldUpdateTicket(ticket)) {
        dispatch(removeClosedTicket(ticket.id));
      };
    });

    socket.on('appMessage', data => {
      if (data.action === "create" && shouldUpdateTicket(data.ticket)) {
        dispatch(updateMessage(data.ticket));
      }
      if (!data.message.read) {
        dispatch(increaseUnreadMessages(data.ticket.id));
      }
    });

    socket.on('disconnect', () => {
    });
    if (user) {
      dispatch(getBoard(user, pageNumber));
    }

    function handleScroll() {
      const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;

      if (scrollTop + window.innerHeight + 50 >= scrollHeight) {
        pageNumber++;
        dispatch(getBoard(user, pageNumber));
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      socket.disconnect();
      window.removeEventListener('scroll', handleScroll);
    };
  }, [user, dispatch]);

  const onDragEnd = (result) => {
    // Reorder card
    const { destination, source, draggableId } = result;
    // Parse draggableId to number before using it
    const parsedDraggableId = parseInt(draggableId, 10);

    if (!destination || !source) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    if (destination.droppableId === source.droppableId) {
      // Show a toast to inform the user that their drag-and-drop action had no effect.
      toast.warn("Same stage records will be order by the last message time.");
      return;
    }

    const start = board.columns[source.droppableId];
    const finish = board.columns[destination.droppableId];

    if (start.id !== finish.id) {
      // Call API to update ticket's stage when it is moved to a new column
      const movedTicketId = parsedDraggableId;
      const newStage = finish.id;
      dispatch(updateStage(movedTicketId, newStage));
    }

    const startCardIds = [...start.cardIds];
    startCardIds.splice(source.index, 1);
    const updatedStart = {
      ...start,
      cardIds: startCardIds.map(id => parseInt(id, 10)),
    };

    const finishCardIds = [...finish.cardIds];
    finishCardIds.splice(destination.index, 0, parsedDraggableId.toString());
    const updatedFinish = {
      ...finish,
      cardIds: finishCardIds.map(id => parseInt(id, 10)),
    };

    dispatch(
      persistCard({
        ...board.columns,
        [updatedStart.id]: updatedStart,
        [updatedFinish.id]: updatedFinish,
      })
    );
  };


  return (
    <Page title="Kanban" sx={{ height: 1 }}>
      <Container maxWidth={false} sx={{ height: 1 }}>
        <HeaderBreadcrumbs
          heading="Kanban - Exclude Closed Chats"
          links={[
            {
              name: 'Dashboard',
              href: PATH_DASHBOARD.root,
            },
            { name: 'Kanban' },
          ]}
        />
        <Scrollbars
          style={{ height: 20, marginBottom: '1rem' }}
          universal
          onScroll={(e) => {
            if (boardRef.current) {
              boardRef.current.scrollLeft = e.target.scrollLeft;  // set the scroll position to boardRef
            }
          }}
          renderThumbHorizontal={({ style, ...props }) =>
            <div
              {...props}
              style={{ 
                ...style, 
                backgroundColor: 'green',
                height: '20px',
                borderRadius: '10px' 
              }}
            />
          }
        >
          <div style={{ minWidth: '3000px' }} />
        </Scrollbars>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="all-columns" direction="horizontal">
            {(provided) => (
              <Stack
                {...provided.droppableProps}
                ref={(ref) => {
                  provided.innerRef(ref);
                  boardRef.current = ref;
                }}
                direction="row"
                alignItems="flex-start"
                spacing={3}
                sx={{ height: 'calc(100% - 32px)', overflowY: 'hidden', overflowX: 'auto' }}
                // Removed the onScroll event here
              >
                {!board.columnOrder.length ? (
                  <SkeletonKanbanColumn />
                ) : (
                  board.columnOrder.map((columnId, index) => (
                    <KanbanColumn index={index} key={columnId} column={board.columns[columnId]} />
                  ))
                )}

                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      </Container>
    </Page>
  );
}
