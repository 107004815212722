import * as React from 'react';
import { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { styled } from "@mui/system";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { Colorize } from "@mui/icons-material";
import ColorPicker from "../ColorPicker";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

const ColorAdornment = styled('div')({
	width: 20,
	height: 20,
});

const TagSchema = Yup.object().shape({
	name: Yup.string()
		.min(3, "Too Short")
		.required("Required")
});

const TagModal = ({ open, onClose, tagId, reload }) => {
	const { user } = useContext(AuthContext);
	const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);

	const initialState = {
		name: "",
		color: ""
	};

	const [tag, setTag] = useState(initialState);

	useEffect(() => {
		(async () => {
			if (!tagId) return;

			const { data } = await api.get(`/tags/${tagId}`);
			setTag(prevState => {
				return { ...prevState, ...data };
			});
		})().catch(err => {
			toastError(err);
		})
	}, [tagId, open]);

	const handleClose = () => {
		setTag(initialState);
		setColorPickerModalOpen(false);
		onClose();
	};


	const handleSaveTag = async values => {
			let filteredValues = {
			color: values.color,
			name: values.name,
			}
		try {
			if (tagId) {
				await api.put(`/tags/${tagId}`, filteredValues);
			} else {
				await api.post("/tags", filteredValues);
			}
			toast.success("Tag saved successfully", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="xs"
			fullWidth
			scroll="paper"
		>
			<DialogTitle id="form-dialog-title">
				{ tagId ? `${i18n.t("tagModal.title.edit")}` : `${i18n.t("tagModal.title.add")}`}
			</DialogTitle>
			<Formik
				initialValues={tag}
				enableReinitialize={true}
				validationSchema={TagSchema}
				onSubmit={(values, actions) => {
					setTimeout(() => {
						handleSaveTag(values);
						actions.setSubmitting(false);
					}, 400);
				}}
			>
				{({ touched, errors, isSubmitting, values }) => (
					<Form>
						<DialogContent dividers>
							<Field
								as={TextField}
								label={i18n.t("tagModal.form.name")}
								name="name"
								error={touched.name && Boolean(errors.name)}
								helperText={touched.name && errors.name}
								variant="outlined"
								margin="dense"
								onChange={(e) => setTag(prev => ({ ...prev, name: e.target.value }))}
								fullWidth
							/>
							<br />
							<Field
								as={TextField}
								fullWidth
								label={i18n.t("tagModal.form.color")}
								name="color"
								id="color"
								error={touched.color && Boolean(errors.color)}
								helperText={touched.color && errors.color}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<ColorAdornment style={{ backgroundColor: values.color }} />
										</InputAdornment>
									),
									endAdornment: (
										<IconButton
											size="small"
											color="default"
											onClick={() => setColorPickerModalOpen(!colorPickerModalOpen)}
										>
											<Colorize />
										</IconButton>
									),
								}}
								variant="outlined"
								margin="dense"
							/>
								{colorPickerModalOpen && (
										<ColorPicker
										style={ { padding: '80px' } }
										id="modal_colorpicker"
										open={ colorPickerModalOpen }
										handleClose={ () => setColorPickerModalOpen(false) }
										onChange={ color => {
											values.color = color;
											setTag(() => {
											return { ...values, color };
											});
										} }
										/>
								)}
						</DialogContent>
						<DialogActions>
							<Button
								onClick={handleClose}
								color="secondary"
								disabled={isSubmitting}
								variant="outlined"
							>
								{i18n.t("tagModal.buttons.cancel")}
							</Button>
							<Button
								type="submit"
								color="primary"
								disabled={isSubmitting}
								variant="contained"
							>
								{tagId
									? `${i18n.t("tagModal.buttons.okEdit")}`
									: `${i18n.t("tagModal.buttons.okAdd")}`}
								{isSubmitting && (
									<CircularProgress size={24} style={{color: green[500]}} />
								)}
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
	};
	
	export default TagModal;