import React from "react";

import { Card, Button } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import TicketHeaderSkeleton from "../TicketHeaderSkeleton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles()((theme) => {
  return {
    ticketHeader: {
      display: "flex",
      borderRadius: '0px !important',
      flex: "none",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      [ theme.breakpoints.down("sm") ]: {
        flexWrap: "wrap",
      },
    },
  }
});

const TicketHeader = ({ loading, children, onBack }) => {
  const { classes } = useStyles();
  const history = useHistory();
  const handleBack = () => {
    if(onBack) {
      onBack();
    } else {
      history.push("/chats");
    }
  };

  return (
    <>
      { loading ? (
        <TicketHeaderSkeleton />
      ) : (
        <Card style={ { borderRadius: '0px !important' } } square className={ classes.ticketHeader }>
          <Button color="primary" onClick={ handleBack }>
            <ArrowBackIos />
          </Button>
          { children }
        </Card>
      ) }
    </>
  );
};

export default TicketHeader;
