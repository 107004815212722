import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Divider from "@mui/material/Divider";
import { Badge } from "@mui/material";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import GridViewIcon from '@mui/icons-material/GridView';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import CodeRoundedIcon from "@mui/icons-material/CodeRounded";
import PublicIcon from '@mui/icons-material/Public';
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';

// icones
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import ForumIcon from '@mui/icons-material/Forum';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CellWifiIcon from '@mui/icons-material/CellWifi';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import BarChartIcon from '@mui/icons-material/BarChart';
import BusinessIcon from '@mui/icons-material/Business';
import "./styleMenu.css"

function ListItemLink(props) {
  const { icon, primary, to, className, id } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={ to } ref={ ref } { ...itemProps } />
      )),
    [ to ]
  );

  return (
    <ListItem id={ id } style={ { listStyle: "none", padding: '5px 17px' } }>
      <ListItem color="primary"
        //window.location.pathname === id ? '' : ''
        sx={ {

          borderRadius: '5px',
          color: (theme) => window.location.pathname === id ?
            theme.palette.primary.main
            : '',

        } }
        button component={ renderLink }
        className={ className }>
        { icon ? <ListItemIcon>{ icon }</ListItemIcon> : null }
        <ListItemText primary={ primary } />
      </ListItem>
    </ListItem>
  );
}

const MainListItems = (isCollapse) => {
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [ connectionWarning, setConnectionWarning ] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0 && user.id) {
        const offlineWhats = whatsApps.filter((whats) => {
          return ((
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          ));
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 5000);
    return () => clearTimeout(delayDebounceFn);
  }, [ whatsApps ]);

  return (
    <List>
      <ListSubheader style={ { borderRadius: '8px', margin: '8px' } }>
        { "Menu" }
      </ListSubheader>
      { user.profile != 'adminSuper' && <>

        <ListItemLink
          to="/chats"
          id="/chats"
          primary={ isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.tickets") }
          icon={ <ForumIcon /> }
        />
        <ListItemLink
          to="/kanban"
          id="/kanban"
          primary={ isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.kanban") }
          icon={ <ViewKanbanIcon /> }
        />
        <ListItemLink
          to="/calendar"
          id="/calendar"
          primary={ isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.calendar") }
          icon={ <CalendarMonthIcon /> }
        />
        <ListItemLink
          to="/quick-answers"
          id="/quick-answers"
          primary={ isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.quickAnswers") }
          icon={ <QuickreplyIcon /> }
        />
        <Can
          role={ user.profile }
          perform="drawer-admin-items:view"
          yes={ () => (
            <>
              <ListSubheader style={ { borderRadius: '8px', margin: '8px' } }>
                { isCollapse.collapse ? i18n.t("mainDrawer.listItems.admin") : i18n.t("mainDrawer.listItems.administration") }
              </ListSubheader>
              <ListItemLink
                to="/dashboard"
                id="/dashboard"
                primary={ isCollapse.collapse ? ' ' : 'Dashboard' }
                icon={ <DashboardCustomizeIcon /> }
              />
              <ListItemLink
                to="/connections"
                id="/connections"
                primary={ isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.connections") }
                icon={
                  <Badge badgeContent={ connectionWarning ? "!" : 0 } color="error">
                    <CellWifiIcon />
                  </Badge>
                }
              />
              <ListItemLink
                to="/tags"
                id="/tags"
                primary={ isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.tags") }
                icon={ <BookmarkIcon /> }
              />
              <ListItemLink
                to="/contacts"
                id="/contacts"
                primary={ isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.contacts") }
                icon={ <ContactPhoneIcon /> }
              />
              <ListItemLink
                to="/users"
                id="/users"
                primary={ isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.users") }
                icon={ <PeopleAltIcon /> }
              />

              <ListItemLink
                to="/teams"
                id="/teams"
                primary={ isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.queues") }
                icon={ <AccountTreeIcon /> }
              />

              <ListItemLink
                to="/settings"
                id="/settings"
                primary={ isCollapse.collapse ? ' ' : i18n.t("mainDrawer.listItems.settings") }
                icon={ <SettingsOutlinedIcon /> }
              />
            </>
          ) }
        />
      </> }
    </List>
  );
};

export default MainListItems;
