import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
import axios from '../../utils/axios';
import api from "../../services/api";
import { dispatch } from '../store';
import { toast } from 'react-toastify';
import openSocket from "socket.io-client";

const socket = openSocket(process.env.REACT_APP_BACKEND_URL);


function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: null,
  board: {
    cards: {},
    columns: {},
    columnOrder: [],
  },
};

function reorderTickets(state) {
  Object.values(state.board.columns).forEach(column => {
    column.cardIds.sort((aId, bId) => {
      const aDate = new Date(state.board.cards[aId].lastMessageAt);
      const bDate = new Date(state.board.cards[bId].lastMessageAt);
      return bDate - aDate;
    });
  });
}

function doesTicketExist(state, ticketId) {
  // check across all columns
  return Object.values(state.board.columns).some(column => column.cardIds.includes(ticketId));
}

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getBoardSuccess(state, action) {
      state.isLoading = false;
      const responseData = action.payload;
      const newTickets = responseData.tickets || [];
      const { pageNumber } = responseData;

      if (pageNumber === 1) {
        state.board.columns = {
          companyinside: { id: 'companyinside', name: 'Company Inside', cardIds: [] },
          draft: { id: 'draft', name: 'New Inquiry', cardIds: [] },
          waitingprice: { id: 'waitingprice', name: 'Waiting Price', cardIds: [] },
          sendquotation: { id: 'sendquotation', name: 'Send Quotation', cardIds: [] },
          followup: { id: 'followup', name: 'Follow Up', cardIds: [] },
          waitingpayment: { id: 'waitingpayment', name: 'Waiting Payment', cardIds: [] },
          production: { id: 'production', name: 'Production', cardIds: [] },
          delivery: { id: 'delivery', name: 'Delivery & Installation', cardIds: [] },
        };
      }

      newTickets.forEach(ticket => {
        const { id, stage } = ticket;

        // Check if the ticket already exists in any column
        if (!doesTicketExist(state, id)) {
          state.board.columns[stage].cardIds.push(id);
        }
      });

      const cards = { ...state.board.cards };

      newTickets.forEach(ticket => {
        const { id } = ticket;
        cards[id] = {
          ...ticket, 
          description: ticket.description || '',
          dueDate: ticket.dueDate || null,
          name: id.toString()
        };
      });

      if (pageNumber === 1) {
        state.board.columnOrder = ['companyinside', 'draft', 'waitingprice', 'sendquotation', 'followup', 'waitingpayment', 'production', 'delivery'];
      }

      state.board = {
        cards,
        columns: state.board.columns,
        columnOrder: state.board.columnOrder,
      };
    },
    persistCard(state, action) {
      const columns = action.payload;
      state.board.columns = columns;
    },
    updateDescriptionSuccess(state, action) {
      const { taskId, newDescription } = action.payload;
      state.board.cards[taskId].description = newDescription;
    },
    updateDueDateSuccess(state, action) {
      const { taskId, newDueDate } = action.payload;
      state.board.cards[taskId].dueDate = newDueDate;
    },
    updateStageSuccess(state, action) {
      const { taskId, newStage } = action.payload;
      state.board.cards[taskId].stage = newStage;
    },
    updateTicket(state, action) {
      const ticket = action.payload;

      // Get the current state
      const currentState = state.board;
      const { stage, id } = ticket;
      
      // Check if the stage has changed and if the ticket exists
      if (doesTicketExist(state, id)) {
        // Find the old stage
        const oldStage = Object.keys(currentState.columns).find(key =>
          currentState.columns[key].cardIds.includes(id)
        );

        if (oldStage !== stage) {
          // Remove the ticket from the old column
          currentState.columns[oldStage].cardIds = currentState.columns[oldStage].cardIds.filter(ticketId => ticketId !== id);

          // Add the ticket to the new column
          currentState.columns[stage].cardIds.push(id);
        }
      } else {
        // The ticket does not exist yet, add it to the 'stage' column
        currentState.columns[stage].cardIds.push(id);
      }

      // Update or create the ticket
      currentState.cards[ticket.id] = { 
        ...(currentState.cards[ticket.id] || {}),
        ...ticket,
        description: ticket.description || '', 
        dueDate: ticket.dueDate || null, 
        lastMessage: ticket.lastMessage, 
        lastMessageAt: ticket.lastMessageAt,
        name: currentState.cards[ticket.id] ? currentState.cards[ticket.id].name : ticket.id.toString(),
        unreadMessages: currentState.cards[ticket.id] ? currentState.cards[ticket.id].unreadMessages + 1 : 1
      };

      state.board = currentState;
    },

    updateMessage(state, action) {
      const ticket = action.payload;

      // Update or create the ticket
      state.board.cards[ticket.id] = { 
        ...(state.board.cards[ticket.id] || {}),
        ...ticket,
        description: ticket.description || '', 
        dueDate: ticket.dueDate || null, 
        lastMessage: ticket.lastMessage, 
        lastMessageAt: ticket.lastMessageAt,
        name: state.board.cards[ticket.id] ? state.board.cards[ticket.id].name : ticket.id.toString(),
        unreadMessages: state.board.cards[ticket.id] ? state.board.cards[ticket.id].unreadMessages : 0
      };

      // Check if the new message is unread and increase the unreadMessages count
      if(ticket.message && !ticket.message.read) {
        state.board.cards[ticket.id].unreadMessages++;
      }

      // Check if ticket already exists in the Kanban
      if (!doesTicketExist(state, ticket.id)) {
        // Add it to the 'draft' column if it's a new ticket
        state.board.columns['draft'].cardIds.unshift(ticket.id);
      }

      reorderTickets(state);
    },
    updateTags(state, action) {
      const ticket = action.payload;

      // Update or create the ticket
      state.board.cards[ticket.id] = { 
        ...(state.board.cards[ticket.id] || {}),
        ...ticket,
        tags: ticket.tags
      };
    },
    reorderTickets: reorderTickets,
    increaseUnreadMessages(state, action) {
      const { ticketId } = action.payload;
      if(state.board.cards[ticketId]){
        state.board.cards[ticketId].unreadMessages++;
      }
    },
    clearUnreadMessages(state, action) {
      const { ticketId } = action.payload;
      if(state.board.cards[ticketId]){
        state.board.cards[ticketId].unreadMessages = 0;
      }
    },
    removeClosedTicket(state, action) {
      const ticketId = action.payload;
      const { columns } = state.board;

      // iterate over each column
      for (let column in columns) {
        // check if the column has the ticketId
        const index = columns[column].cardIds.indexOf(ticketId);

        if (index !== -1) {
          // remove the ticketId from the column
          columns[column].cardIds.splice(index, 1);
        }
      }

      // remove the ticketId from cards
      state.board.cards = omit(state.board.cards, ticketId);
    },
    removeUnUpdateableTicket(state, action) {
      const ticketId = action.payload;

      // Get the current state
      const currentState = state.board;
      
      // Find the old stage
      const oldStage = Object.keys(currentState.columns).find(key =>
        currentState.columns[key].cardIds.includes(ticketId)
      );

      if (oldStage) {
        // Remove the ticket from the old column
        currentState.columns[oldStage].cardIds = currentState.columns[oldStage].cardIds.filter(id => id !== ticketId);
        // Remove the ticket from the cards object
        delete currentState.cards[ticketId];
      }

      state.board = currentState;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

export function getBoard(user, pageNumber = 1) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { profile, queues } = user; 
      const queueIds = JSON.stringify(user.queues.map(queue => queue.id));
      const showAll = profile.toUpperCase() === "ADMIN";

      // stages that you want to show in the Kanban view
      const stage = ['companyinside', 'draft', 'waitingprice', 'sendquotation', 'followup', 'waitingpayment', 'production', 'delivery'];
      const status = ['open', 'pending']; // include only open or pending tickets

      const params = {
        pageNumber,
        queueIds,
        showAll,
        stage,  // pass the stages array as a parameter
        status   // pass the status parameter
      };

      const response = await api.get('/chats', { params });
      const allTickets = response.data.tickets;

      // Sort tickets by lastMessageAt in descending order
      allTickets.sort((a, b) => {
        const aDate = new Date(a.lastMessageAt);
        const bDate = new Date(b.lastMessageAt);
        return bDate - aDate;
      });

      dispatch(slice.actions.getBoardSuccess({ tickets: allTickets, pageNumber }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function persistCard(columns) {
  return () => {
    dispatch(slice.actions.persistCard(columns));
  };
}


export function updateStage(ticketId, newStage) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await api.put(`/chats/${ticketId}`, { stage: newStage });
      dispatch(slice.actions.updateStageSuccess({ taskId: ticketId, newStage }));
      toast.success('Stage updated successfully.');
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function updateCard(ticketId, updatedFields) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await api.put(`/chats/${ticketId}`, updatedFields);
      
      if(response.data) {
        const { description, dueDate } = response.data;

        dispatch(slice.actions.updateDescriptionSuccess({ taskId: ticketId, newDescription: description }));
        dispatch(slice.actions.updateDueDateSuccess({ taskId: ticketId, newDueDate: dueDate }));
        
        toast.success('Chat updated successfully.');
      }
      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const { updateTicket, removeUnUpdateableTicket } = slice.actions;
export const { updateMessage } = slice.actions;
export const { updateTags } = slice.actions;

export function increaseUnreadMessages(ticketId) {
  return () => {
    dispatch(slice.actions.increaseUnreadMessages({ ticketId }));
  };
}
export const { clearUnreadMessages: clearUnreadMessagesAction } = slice.actions;
export function clearUnreadMessages(ticketId) {
  return () => {
    dispatch(clearUnreadMessagesAction({ ticketId }));
  };
}

export const { removeClosedTicket: removeClosedTicketAction } = slice.actions;
export function removeClosedTicket(ticketId) {
  return () => {
    dispatch(removeClosedTicketAction(ticketId));
    toast.success("Closed chat won't display in Kanban, if reopen it or customer send you a new message, It will automatic display at New Inquiry stage.");
  };
}

