import React, { useState } from "react";

import { makeStyles } from 'tss-react/mui';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import Link from "@mui/material/Link";
import InputLabel from "@mui/material/InputLabel";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import NoteIcon from '@mui/icons-material/Note';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { i18n } from "../../translate/i18n";
import ContactModal from "../ContactModal";
import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import MarkdownWrapper from "../MarkdownWrapper";

const drawerWidth = 260;

const useStyles = makeStyles()((theme) => {
  return {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      display: "flex",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    header: {
      display: "flex",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",

      alignItems: "center",
      padding: theme.spacing(0, 1),
      minHeight: "73px",
      justifyContent: "flex-start",
    },
    content: {
      display: "flex",

      flexDirection: "column",
      padding: "8px 0px 8px 8px",
      height: "100%",
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },

    contactAvatar: {
      margin: '15px !important',
      width: '160px !important',
      height: '160px !important',
    },

    contactHeader: {
      display: "flex",
      padding: 8,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      "& > *": {
        margin: 4,
      },
    },

    contactDetails: {
      marginTop: 8,
      padding: 8,
      display: "flex",
      flexDirection: "column",
    },
    contactExtraInfo: {
      marginTop: 4,
      padding: 6,
    },
  }
});

const ContactDrawer = ({ open, handleDrawerClose, contact, loading }) => {
  const { classes } = useStyles();
  const [ modalOpen, setModalOpen ] = useState(false);

  return (
    <Drawer
      className={ classes.drawer }
      variant="persistent"
      anchor="right"
      open={ open }
      PaperProps={ { style: { position: "absolute" } } }
      BackdropProps={ { style: { position: "absolute" } } }
      ModalProps={ {
        container: document.getElementById("drawer-container"),
        style: { position: "absolute" },
      } }
      classes={ {
        paper: classes.drawerPaper,
      } }
    >
      <div className={ classes.header }>
        <IconButton onClick={ handleDrawerClose }>
          <CloseIcon />
        </IconButton>
        <Typography style={ { justifySelf: "center" } }>
          { i18n.t("contactDrawer.header") }
        </Typography>
      </div>
      { loading ? (
        <ContactDrawerSkeleton classes={ classes } />
      ) : (
        <div className={ classes.content }>
          <Paper square variant="outlined" className={ classes.contactHeader }>
            <Avatar
              alt={ contact.name }
              src={ contact.profilePicUrl }
              className={ classes.contactAvatar }
            ></Avatar>
            <Typography>{ contact.name }</Typography>
            <Typography>{ contact.number }</Typography>
            <Typography>{ contact.email }</Typography>
            <Typography>{ contact.quotation }</Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={ () => setModalOpen(true) }
            >
              { i18n.t("contactDrawer.buttons.edit") }
            </Button>
          </Paper>
          <Paper square variant="outlined" className={ classes.contactDetails }>
            <ContactModal
              open={ modalOpen }
              onClose={ () => setModalOpen(false) }
              contactId={ contact.id }
            ></ContactModal>
            <Typography variant="subtitle1">
              { i18n.t("contactDrawer.extraInfo") }
            </Typography>
            { contact?.extraInfo?.map(info => (
              <Paper
                key={ info.id }
                square
                variant="outlined"
                className={ classes.contactExtraInfo }
              >
                <InputLabel>{ info.name }</InputLabel>
                <Typography component="div" noWrap style={ { paddingTop: 2 } }>
                  <MarkdownWrapper>{ info.value }</MarkdownWrapper>
                </Typography>
              </Paper>
            )) }
          </Paper>
        </div>
      ) }
    </Drawer>
  );
};

export default ContactDrawer;
