import React, { useEffect } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
import Chats from "../pages/Chats";
import Login from "../pages/Login/";
import Connections from "../pages/Connections/";
import Settings from "../pages/Settings/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import QuickAnswers from "../pages/QuickAnswers/";
import Teams from "../pages/Teams";
import Tags from "../pages/Tags";
import Kanban from "../pages/Kanban";
import Calendar from "../pages/Calendar"
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Route from "./Route";

const Routes = () => {

  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <WhatsAppsProvider>
            <LoggedInLayout>
              <Route exact path="/dashboard" component={Dashboard} isPrivate />
              <Route exact path="/chats/:ticketId?" component={Chats} isPrivate />
              <Route exact path="/kanban" component={Kanban} isPrivate />
              <Route exact path="/calendar" component={Calendar} isPrivate />
              <Route exact path="/connections" component={Connections} isPrivate />
              <Route exact path="/contacts" component={Contacts} isPrivate />
              <Route exact path="/users" component={Users} isPrivate />
              <Route exact path="/quick-answers" component={QuickAnswers} />
              <Route exact path="/settings" component={Settings} isPrivate />
              <Route exact path="/teams" component={Teams} isPrivate />
              <Route exact path="/tags" component={Tags} isPrivate />
            </LoggedInLayout>
          </WhatsAppsProvider>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter >
  );
};

export default Routes;
