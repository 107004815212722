import PropTypes from 'prop-types';
import * as Yup from 'yup';
import merge from 'lodash/merge';
import { isBefore } from 'date-fns';
import { useSnackbar } from 'notistack';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Stack, Button, Tooltip, TextField, IconButton, DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// redux
import { useDispatch } from '../../../redux/store';
import { updateEvent } from '../../../redux/slices/calendar';
// components
import Iconify from '../../../components/Iconify';
import { ColorSinglePicker } from '../../../components/color-utils';
import { FormProvider, RHFTextField, RHFSwitch } from '../../../components/hook-form';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Chip, Typography } from '@mui/material';

// ----------------------------------------------------------------------


const COLOR_OPTIONS = [
  '#00AB55', // theme.palette.primary.main,
  '#1890FF', // theme.palette.info.main,
  '#54D62C', // theme.palette.success.main,
  '#FFC107', // theme.palette.warning.main,
  '#FF4842', // theme.palette.error.main
  '#04297A', // theme.palette.info.darker
  '#7A0C2E', // theme.palette.error.darker
];

const getInitialValues = (event, range) => {
  const _event = {
    title: '',
    description: '',
    start: range ? new Date(range.start) : new Date(),
  };

  if (event || range) {
    return merge({}, _event, event);
  }

  return _event;
};

// ----------------------------------------------------------------------

CalendarForm.propTypes = {
  event: PropTypes.object,
  range: PropTypes.object,
  onCancel: PropTypes.func,
};

export default function CalendarForm({ event, range, onCancel }) {
  const STAGES = [
    { id: 'companyinside', label: 'Company Inside' },
    { id: 'draft', label: 'New Inquiry' },
    { id: 'waitingprice', label: 'Waiting Price' },
    { id: 'sendquotation', label: 'Send Quotation' },
    { id: 'followup', label: 'Follow Up' },
    { id: 'waitingpayment', label: 'Waiting Payment' },
    { id: 'production', label: 'Production' },
    { id: 'delivery', label: 'Delivery or Installation' },
  ];

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const isCreating = Object.keys(event).length === 0;

  const EventSchema = Yup.object().shape({
    title: Yup.string().max(255).required('Title is required'),
    description: Yup.string().max(5000),
  });

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues: getInitialValues(event, range),
  });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const newEvent = {
        title: data.title,
        description: data.description,
        start: data.start,
        end: data.end,
        stage: data.stage
      };
      if (event.id) {
        dispatch(updateEvent(event.id, newEvent));
        enqueueSnackbar('Update success!');
      }
      onCancel();
      reset();
    } catch (error) {
      // ;
    }
  };

  const values = watch();



  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ p: 3 }}>
        <RHFTextField name="title" label="Teamchat ID" disabled={true} />

        <RHFTextField name="description" label="Description" multiline rows={4} />

        <FormControl fullWidth>
          <Typography variant="body1" gutterBottom>
            Tags
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2, // this will add a gap between the Chips
              alignItems: 'center', // align vertically
              minHeight: 56, // mimic TextField height
              p: 1, // padding
              border: '1px solid rgba(0, 0, 0, 0.23)', // mimic TextField border
              borderRadius: 1 // border radius
            }}
          >
            {values.tags && values.tags.map((tag) => (
              <Chip
                key={tag.id}
                label={tag.name}
                style={{ backgroundColor: tag.color }}
              />
            ))}
          </Box>
        </FormControl>

        <Controller
          name="stage"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel id="stage-label">Stage</InputLabel>
              <Select {...field} labelId="stage-label" label="Stage">
                {STAGES.map((stage) => (
                  <MenuItem key={stage.id} value={stage.id}>
                    {stage.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        <Controller
          name="start"
          control={control}
          render={({ field }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDateTimePicker
                {...field}
                value={new Date(field.value)}
                label="Start date"
                inputFormat="dd/MM/yyyy hh:mm a"
                textField={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          )}
        />
      </Stack>

      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Cancel
        </Button>

        <LoadingButton type="submit" variant="contained" loading={isSubmitting} loadingIndicator="Loading...">
          Save
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
