import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from 'tss-react/mui';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { i18n } from "../../translate/i18n";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ColorPicker from "../ColorPicker";
import { IconButton, InputAdornment } from "@mui/material";
import { Colorize } from "@mui/icons-material";

import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginRight: theme.spacing(1),
      flex: 1,
    },

    btnWrapper: {
      position: "relative",
    },

    buttonProgress: {
      color: green[ 500 ],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    colorAdorment: {
      width: 20,
      height: 20,
    },
  }
});

const QueueSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(400, "Too Long!")
    .required("Required"),
  color: Yup.string().min(3, "Too Short!").max(10, "Too Long!").required(),
  greetingMessage: Yup.string(),
});

const QueueModal = ({ open, onClose, queueId }) => {
  const { user: loggedInUser } = useContext(AuthContext);

  const { classes } = useStyles();

  const initialState = {
    name: "",
    color: "",
    greetingMessage: ""
  };

  const [ colorPickerModalOpen, setColorPickerModalOpen ] = useState(false);
  const [ queue, setQueue ] = useState(initialState);
  const greetingRef = useRef();

  useEffect(() => {
    (async () => {
      if (!queueId) return;
      try {
        const { data } = await api.get(`/team/${ queueId }`);

        setQueue(prevState => {
          return { ...prevState, ...data };
        });
      } catch (err) {
        toastError(err);
      }
    })();

    return () => {
      setQueue({
        name: "",
        color: "",
        greetingMessage: "",
      });
    };
  }, [ queueId, open ]);

  const handleClose = () => {
    onClose();
    setQueue(initialState);
  };

  const handleSaveQueue = async values => {

    let filteredValues = {
      color: values.color,
      greetingMessage: values.greetingMessage,
      name: values.name,
    }
    try {
      if (queueId) {
        await api.put(`/team/${ queueId }`, filteredValues);
      } else {
        await api.post("/team", filteredValues);
      }
      toast.success("Team saved successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={ classes.root }>
      <Dialog open={ open } onClose={ handleClose } scroll="paper">
        <DialogTitle>
          { queueId
            ? `${ i18n.t("queueModal.title.edit") }`
            : `${ i18n.t("queueModal.title.add") }` }
        </DialogTitle>
        <Formik
          initialValues={ queue }
          enableReinitialize={ true }
          onSubmit={ (values, actions) => {
            setTimeout(() => {
              handleSaveQueue(values);
              actions.setSubmitting(false);
            }, 400);
          } }
        >
          { ({ touched, errors, isSubmitting, values }) => (
            <Form>
              <DialogContent dividers>
                <Field
                  as={ TextField }
                  label={ i18n.t("queueModal.form.name") }
                  name="name"
                  error={ touched.name && Boolean(errors.name) }
                  helperText={ touched.name && errors.name }
                  variant="outlined"
                  margin="dense"
                  className={ classes.textField }
                />
                <Field
                  as={ TextField }
                  label={ i18n.t("queueModal.form.color") }
                  name="color"
                  id="color"
                  onClick={ () => setColorPickerModalOpen(true) }
                  error={ touched.color && Boolean(errors.color) }
                  helperText={ touched.color && errors.color }
                  InputProps={ {
                    startAdornment: (
                      <InputAdornment position="start">
                        <div
                          style={ { backgroundColor: values.color } }
                          className={ classes.colorAdorment }
                        ></div>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <IconButton
                        size="small"
                        color="default"
                      >
                        <Colorize />
                      </IconButton>
                    ),
                  } }
                  variant="outlined"
                  margin="dense"
                />
                <ColorPicker
                  style={ { padding: '80px' } }
                  id="modal_colorpicker"
                  open={ colorPickerModalOpen }
                  handleClose={ () => setColorPickerModalOpen(false) }
                  onChange={ color => {
                    values.color = color;
                    setQueue(() => {
                      return { ...values, color };
                    });
                  } }
                />
                {
                  <>
                    <div>
                      <Field
                        as={ TextField }
                        label={ i18n.t("queueModal.form.description") }
                        type="description"
                        fullWidth
                        name="description"
                        variant="outlined"
                        margin="dense"
                      />
                    </div>
                  </> }
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={ handleClose }
                  color="secondary"
                  disabled={ isSubmitting }
                  variant="outlined"
                >
                  { i18n.t("queueModal.buttons.cancel") }
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={ isSubmitting }
                  variant="contained"
                  className={ classes.btnWrapper }
                >
                  { queueId
                    ? `${ i18n.t("queueModal.buttons.okEdit") }`
                    : `${ i18n.t("queueModal.buttons.okAdd") }` }
                  { isSubmitting && (
                    <CircularProgress
                      size={ 24 }
                      className={ classes.buttonProgress }
                    />
                  ) }
                </Button>
              </DialogActions>
            </Form>
          ) }
        </Formik>
      </Dialog>
    </div >
  );
};

export default QueueModal;
