import React, { useContext, useEffect, useRef, useState } from "react";

import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MoveDownIcon from '@mui/icons-material/MoveDown';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import TransferTicketModal from "../TransferTicketModalCustom";
import toastError from "../../errors/toastError";
import { Can } from "../Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { TicketActionButtons } from "../TicketActionButtons";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
	return {
		mobileReturn: {
			'@media (min-width: 1600px)': {
				display: 'none'
			},
		},
		mobileResolve: {
			'@media (min-width: 1000px)': {
				display: 'none'
			},
		},
	}
});

const TicketOptionsMenu = ({ ticket, menuOpen, handleClose, anchorEl, onReturn, onResolve }) => {

	const { classes } = useStyles();

	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleDeleteTicket = async () => {
		try {
			await api.delete(`/chats/${ticket.id}`);
		} catch (err) {
			toastError(err);
		}
	};

	const handleOpenConfirmationModal = e => {
		setConfirmationOpen(true);
		handleClose();
	};

	const handleOpenTransferModal = e => {
		setTransferTicketModalOpen(true);
		handleClose();
	};

	const handleCloseTransferTicketModal = () => {
		if (isMounted.current) {
			setTransferTicketModalOpen(false);
		}
	};

	return (
		<>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				getcontentanchorel={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={menuOpen}
				onClose={handleClose}
			>
				<MenuItem className={classes.mobileResolve} onClick={e => { handleClose(); onResolve(); }}>
					<CheckCircleOutlineIcon />&nbsp;{i18n.t("messagesList.header.buttons.resolved")}
				</MenuItem>
				<MenuItem className={classes.mobileReturn} onClick={e => { handleClose(); onReturn(); }}>
					<ReplayIcon />&nbsp;{i18n.t("messagesList.header.buttons.return")}
				</MenuItem>
				<MenuItem onClick={handleOpenTransferModal}>
					<MoveDownIcon />&nbsp;{i18n.t("ticketOptionsMenu.transfer")}
				</MenuItem>
				<Can
					role={user.profile}
					perform="ticket-options:deleteTicket"
					yes={() => (
						<MenuItem onClick={handleOpenConfirmationModal}>
							<DeleteIcon />&nbsp;{i18n.t("ticketOptionsMenu.delete")}
						</MenuItem>
					)}
				/>
			</Menu>
			<ConfirmationModal
				title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")}${ticket.id
					} ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")} ${ticket.contact.name
					}?`}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleDeleteTicket}
			>
				{i18n.t("ticketOptionsMenu.confirmationModal.message")}
			</ConfirmationModal>
			<TransferTicketModal
				modalOpen={transferTicketModalOpen}
				onClose={handleCloseTransferTicketModal}
				ticketid={ticket.id}
			/>
		</>
	);
};

export default TicketOptionsMenu;
