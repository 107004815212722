import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Drawer,
  Button,
  Avatar,
  Tooltip,
  Divider,
  MenuItem,
  TextField,
  Typography,
  OutlinedInput,
  Chip,
} from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import { IconButtonAnimate } from '../../../components/animate';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

KanbanTaskDetails.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  card: PropTypes.object,
  onDeleteTask: PropTypes.func,
  onUpdateTask: PropTypes.func,
};

export default function KanbanTaskDetails({ card, isOpen, onClose, onDeleteTask, onUpdateTask }) {
  const isDesktop = useResponsive('up', 'sm');

  const fileInputRef = useRef(null);
  const [taskCompleted, setTaskCompleted] = useState(card.completed);

  const { name, queue, user, contact, tags = [] } = card;

  const [dueDate, setDueDate] = useState(card.dueDate ? new Date(card.dueDate) : null);
  const [description, setDescription] = useState(card.description);
  const [openPicker, setOpenPicker] = useState(false);

  useEffect(() => {
    setDescription(card.description);
    setDueDate(card.dueDate ? new Date(card.dueDate) : null);
  }, [card.description, card.dueDate]);

  const getContrast = (hexcolor) => {
    hexcolor = hexcolor.replace("#", "");
    const r = parseInt(hexcolor.substr(0,2),16);
    const g = parseInt(hexcolor.substr(2,2),16);
    const b = parseInt(hexcolor.substr(4,2),16);
    const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return brightness > 155 ? '#000000' : '#ffffff';
  }

  const handleDueDateChange = (date) => {
    setDueDate(date);
  };


  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleSaveChanges = () => {
    onUpdateTask(card.id, { description, dueDate: dueDate ? dueDate.toISOString() : null });
  };

  const handleAttach = () => {
    fileInputRef.current?.click();
  };

  const handleToggleCompleted = () => {
    setTaskCompleted((prev) => !prev);
  };

  // add these two handlers
  const onOpenPicker = () => {
    setOpenPicker(true);
  };

  const onClosePicker = () => {
    setOpenPicker(false);
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right" PaperProps={{ sx: { width: { xs: 1, sm: 480 } } }}>
        <Stack p={2.5} direction="row" alignItems="center">
          {!isDesktop && (
            <>
              <Tooltip title="Back">
                <IconButtonAnimate onClick={onClose} sx={{ mr: 1 }}>
                  <Iconify icon={'eva:arrow-ios-back-fill'} width={20} height={20} />
                </IconButtonAnimate>
              </Tooltip>
            </>
          )}

          {/* <Button
            size="small"
            variant="outlined"
            color={taskCompleted ? 'primary' : 'inherit'}
            startIcon={!taskCompleted && <Iconify icon={'eva:checkmark-fill'} width={16} height={16} />}
            onClick={handleToggleCompleted}
          >
            {taskCompleted ? 'Complete' : 'Mark complete'}
          </Button>

          <Stack direction="row" spacing={1} justifyContent="flex-end" flexGrow={1}>
            <Tooltip title="Like this">
              <IconButtonAnimate size="small">
                <Iconify icon={'ic:round-thumb-up'} width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>

          </Stack> */}
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ px: 2.5, py: 3 }}>
            <OutlinedInput
              fullWidth
              multiline
              size="small"
              placeholder="TeamChat ID"
              value={name}
              sx={{
                typography: 'h6',
                '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
              }}
            />

            <Stack direction="row">
                <LabelStyle sx={{ mt: 2 }}>User</LabelStyle>
                <OutlinedInput
                    fullWidth
                    size="small"
                    value={user ? user.name : ''}
                    readOnly
                    sx={{ typography: 'body2' }}
                />
            </Stack>

            <Stack direction="row">
                <LabelStyle sx={{ mt: 2 }}>Team</LabelStyle>
                <OutlinedInput
                    fullWidth
                    size="small"
                    value={queue ? queue.name : ''}
                    readOnly
                    sx={{ typography: 'body2' }}
                />
            </Stack>

            <Stack direction="row">
                <LabelStyle sx={{ mt: 2 }}>Contact Name</LabelStyle>
                <OutlinedInput
                    fullWidth
                    size="small"
                    value={contact ? contact.name : ''}
                    readOnly
                    sx={{ typography: 'body2' }}
                />
            </Stack>


            <Stack direction="row">
                <LabelStyle sx={{ mt: 2 }}>Contact Number</LabelStyle>
                <OutlinedInput
                    fullWidth
                    size="small"
                    value={contact ? contact.number : ''}
                    readOnly
                    sx={{ typography: 'body2' }}
                />
            </Stack>

            <Stack direction="row">
              <LabelStyle sx={{ mt: 2 }}>Tags</LabelStyle>
              <Stack direction="row" flexWrap="wrap" spacing={1}>
                {tags.map((tag) => (
                  <Chip 
                    key={tag.id} 
                    label={tag.name} 
                    style={{ backgroundColor: tag.color, color: getContrast(tag.color), borderRadius: 16, margin: 4 }} 
                  />
                ))}
              </Stack>
            </Stack>

            <Stack direction="row" alignItems="center">
              <LabelStyle> Next Activity Date</LabelStyle>
              <>
                {
                  <Tooltip title="Next Activity Date">
                    <IconButtonAnimate
                      onClick={onOpenPicker}
                      sx={{
                        p: 1,
                        ml: 0.5,
                        border: (theme) => `dashed 1px ${theme.palette.divider}`,
                      }}
                    >
                      <Iconify icon={'eva:plus-fill'} width={20} height={20} />
                    </IconButtonAnimate>
                  </Tooltip>
                }

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDateTimePicker
                open={openPicker}
                onClose={onClosePicker}
                onOpen={onOpenPicker}
                value={dueDate}
                onChange={handleDueDateChange}
                textField={() => {}}
              />
            </LocalizationProvider>
              </>
            </Stack>

            <Stack direction="row">
              <LabelStyle sx={{ mt: 2 }}>Description</LabelStyle>
              <OutlinedInput
                fullWidth
                multiline
                rows={3}
                size="small"
                placeholder="Description and notes for this chat"
                value={description}
                onChange={handleDescriptionChange}
                sx={{ typography: 'body2' }}
              />
            </Stack>
          </Stack>

        </Scrollbar>
        <Button 
          onClick={handleSaveChanges}
          sx={{
            backgroundColor: 'green', // set background color to green
            color: 'white', // set text color to white
            fontSize: '1.2em', // reduce font size
            padding: '8px 16px', // reduce padding
            '&:hover': {
              backgroundColor: 'darkgreen', // change background color when hovered
            },
          }}
        >
          Save
        </Button>


        <Divider />
      </Drawer>

    </>
  );
}
